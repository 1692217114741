import * as React from 'react';
import styled from 'styled-components';
import { BoxWrapper, Row, Spacer, Text } from '../../parts';
import banner_ny from '../../assets/people_walking.jpeg';
import logoarea from '../../assets/pmlogopanel.png';
import { useState } from 'react';

import { FormModal } from './FormModal';

import ticketsImage from '../../assets/tickets.png';
import { core } from 'core';
import { useSimpleEvent } from 'simple-core-state';

interface BusinessViewProps {}

export const openModal = () => {};

export const BusinessView: React.FC<BusinessViewProps> = (props) => {
	const {} = props;
	const modalListener = openModal();

	const [Modalopen, setModalopen] = useState(false);

	useSimpleEvent(core._events['buss-modal'], (e) => {
		setModalopen(true);
	});

	return (
		<BusinessViewBody>
			{Modalopen && <FormModal onClose={() => setModalopen(false)} />}

			<TopBanner src={banner_ny} width="100%" style={{ maxHeight: 380, objectFit: 'cover', position: 'absolute', zIndex: 5 }} />

			<Text size={40} color="white" weight="bold" center style={{ marginTop: 140, zIndex: 6, textShadow: 'rgb(34 34 34) 4px 4px 7px' }}>
				If you like reaching new customers, let us introduce you to ours.
			</Text>

			<Spacer size={230} />

			<Text size={50} weight="bold" center>
				We Do the Work. You just do you
			</Text>

			<Spacer size={50} />

			<Wrapper>
				<Text color="#525252" size={18} center>
					For qualifying businesses, getting started is a snap. Simply complete a merchant enrollment form, provide us with details about your business, and work with the{' '}
					<strong>Live Rewards </strong>
					marketing team to create an eye-catching, traffic-driving offer. From there, <strong>Live Rewards</strong> goes to work. We promote your business to our private network
					of customers on our members-only website, via mobile devices, in email, etc. All you have to do is show up, honor your offer and be amazing – as only your business can
					be.
				</Text>
			</Wrapper>

			<Spacer size={130} />

			<div style={{ backgroundColor: '#eeeeee', width: '100%', padding: '50px 0px' }}>
				<Wrapper>
					<Row>
						<img src={ticketsImage} height="150" />
						<Spacer size={150} />
						<div>
							<Text size={30} weight="bold" color="#027DFD">
								No Cost. No Risk. No Brainer.
							</Text>
							<Spacer size={10} />
							<Text>
								At <strong>Live Rewards</strong>, we believe local merchants shouldn’t have to pay to connect with customers in their own backyard. Our private network is available
								for FREE to any business whose products/services fit the consumer preferences of our membership base.
							</Text>
						</div>
					</Row>
				</Wrapper>
			</div>

			<Spacer size={110} />

			<Wrapper style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<Text size={44} weight="bold" style={{ textAlign: 'center' }} color="#027DFD">
					Top Brands Trust Live Rewards
				</Text>
				<Spacer size={20} />
				<img src={logoarea} style={{ width: '100%' }} />
			</Wrapper>

			<Spacer size={150} />

			<GetAccessBanner>
				<Text size={40} color="white" weight="bold" center>
					See What Live Rewards Can Do For You
				</Text>
				<Spacer size={30} />
				<GetaccesBtn onClick={() => setModalopen(true)}>ENROLL NOW</GetaccesBtn>
			</GetAccessBanner>

			<Spacer size={50} />

			<BoxWrapper>
				<Text size={38} weight="bold">
					More Deals In More Places
				</Text>
				<Spacer size={20} />

				<Text>
					Live Rewards mobile coupon offers are available in every retail community from coast to coast. No other program can offer a collection of mobile coupons this size,
					spanning both national brands and local merchants in such popular categories as dining, shopping, hotels, recreation and auto services, and more.
				</Text>
				<Spacer size={5} />
				<Text>
					In order to be partnered with Live Rewards a business must offer a better discount on our network than what is available to the general public, that way we can ensure our
					members are always getting the best deal.
				</Text>
			</BoxWrapper>
			<Spacer size={50} />

			<PartnerShipBanner>
				<Text size={35} weight="bold" center>
					Learn More About Our Exclusive Partnership Opportunities
				</Text>
				<Spacer size={20} />
				<GetaccesBtn onClick={() => window.location.assign('/business/partnership')}>Learn more</GetaccesBtn>
			</PartnerShipBanner>

			<Spacer size={120} />

			<Spacer size={50} />
			<Spacer size={50} />
		</BusinessViewBody>
	);
};

const BusinessViewBody = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TopBanner = styled.img`
	position: absolute;
	object-fit: cover;
	z-index: 5;
	max-height: 380px;
	min-height: 380px;
`;

const GetAccessBanner = styled.div`
	width: 100%;
	height: 240px;
	background: linear-gradient(135deg, #00ffd4 0%, #00cff9 27%, #007cff 59%, #681aea 100%);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const PartnerShipBanner = styled.div`
	width: 100%;
	height: 200px;
	background-color: #e5e5e5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const FormBox = styled.div`
	display: flex;
	flex-direction: column;

	input {
		margin-bottom: 10px;
	}
`;

const Button = styled.button`
	padding: 12px;
	border-radius: 8px;
	color: white;
	border: none;
	cursor: pointer;
	background-color: #1372ed;
	font-weight: bold;

	transition: opacity 0.1s ease;
	&:hover {
		opacity: 0.7;
	}
`;

const Wrapper = styled.div`
	max-width: 900px;
	@media only screen and (min-width: 900px) {
		padding: unset;
		max-width: 900px;
		margin: 0 auto;
	}
`;

const Box = styled.div`
	max-width: 300px;
	p {
		&:first-child {
			font-weight: 600;
			font-size: 20px;
			margin-bottom: 10px;
		}
	}
`;

const PlatformBox = styled.div`
	&:first-child {
		div {
			background-color: red;
		}
	}
`;

const BannerSmalBox = styled.div`
	max-width: 300px;
	p {
		&:nth-child(3) {
			font-weight: 600;
			height: 100%;
		}
	}
`;

const BannerBox = styled.div`
	background-color: #f1f1f1;
	width: 100%;
	padding: 50px 0px;
`;

const Input = styled.input`
	padding: 12px;
	border-radius: 5px;
	width: 250px;
	outline: none;
	/* background-color: #ececec; */
	/* border: solid 1px black; */
	border: solid 1px #e7e7e7;
`;

const TextAread = styled.textarea`
	border: solid 1px #e7e7e7;
	margin-bottom: 10px;
	border-radius: 5px;
	padding: 12px;
`;

const GetaccesBtn = styled.button`
	padding: 10px 12px;
	outline: none;
	border-radius: 6px;
	border: none;
	cursor: pointer;
	font-weight: bold;
	background-color: white;
	border: solid 2px black;

	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

	&:hover {
		opacity: 0.7;
		transform: translateY(-2px);
	}
`;
