import * as React from 'react';
import styled from 'styled-components';
import { Row, Spacer, Text } from '../../parts';

export const AboutView: React.FC = (props) => {
	return (
		<ContactViewBody>
			<Spacer size={20} />
			<HeadingTitle weight="bold" style={{ letterSpacing: -0.8, lineHeight: 1.1, textAlign: 'center', maxWidth: 870 }}>
				We are committed to getting you the best offers possible
			</HeadingTitle>
			<Spacer size={20} />
			<Text size={22} style={{ textAlign: 'center', opacity: 0.6 }}>
				Since 2019, we’ve been working on making the best discount and savings app for our members to save the most amount of money possible. With our over 1000 national retail
				partners, we have made sure that there are savings wherever you shop.
			</Text>

			<Spacer size={50} />
			<Spacer size={50} />

			<Text size={16} weight="bold" style={{ opacity: 0.5, letterSpacing: 0.5 }}>
				WHAT WE STAND FOR
			</Text>

			<Spacer size={5} />

			<Text size={34} weight="bold">
				Our principles
			</Text>

			<Spacer size={5} />

			<Text size={18} style={{ letterSpacing: 0.5, opacity: 0.6 }}>
				These four principles drive everything we do.
			</Text>

			<Spacer size={110} />

			<Boxes>
				<Box>
					<BoxTitle>Discounts you can’t get anywhere else</BoxTitle>
					<BoxDesc>
						in order to be parterned with Live Rewards, a company must provide an offer that is better than an offer avaialbe to the general public. Live Rewards is consistently
						making sure you are getting the best deal possible.
					</BoxDesc>
				</Box>
				<Box>
					<BoxTitle>Saving you money</BoxTitle>
					<BoxDesc>
					We at Live Rewards know what it feels like to overpay and feel like you are not getting a good deal. Thats why we started Live Rewards so you can feel you are always getting the best price no matter where you shop.
					</BoxDesc>
				</Box>
			</Boxes>

			<Boxes>
				<Box>
					<BoxTitle>Easy to use</BoxTitle>
					<BoxDesc>
						There is nothing worse than carrying around a ton of coupons and hoping that your brought the right ones with you so you can save on a purchase, not to mention how long it takes to carefully cut those coupons. That's why in everything we do, we make it as easy as possible. Our award winning smartphone app was designed to be extremely simple and easy to use.
					</BoxDesc>
				</Box>

				<Box>
					<BoxTitle>Focusing on improvement</BoxTitle>
					<BoxDesc>
						At Live Rewards, we are always looking for ways to improve our members experience. From seeking out better offers from our partners, to making our app more powerfull and convenient, we are commited to growing and improving in all areas.
					</BoxDesc>
				</Box>

			</Boxes>

			<Spacer size={50} />
			<Spacer size={50} />
			<Spacer size={50} />
		</ContactViewBody>
	);
};

const ContactViewBody = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	@media only screen and (min-width: 900px) {
		padding: unset;
		max-width: 900px;
		margin: 0 auto;
	}
`;

const Boxes = styled(Row)`
	justify-content: space-between;
	align-items: baseline;

	@media only screen and (max-width: 900px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const Box = styled.div`
	width: 50%;
	margin: 10px;
	height: 100%;
	margin-bottom: 25px;

	@media only screen and (max-width: 800px) {
		width: 90%;
		height: unset;
		margin-bottom: 50px;
	}
`;

const BoxTitle = styled(Text).attrs({ size: 26, weight: 'bold' })`
	margin-bottom: 15px;
`;

const BoxDesc = styled(Text).attrs({ size: 19 })`
	opacity: 0.7;
	letter-spacing: 0.4;
	line-height: 1.3;
`;

const HeadingTitle = styled(Text)`
	font-size: 55px;
	@media only screen and (max-width: 450px) {
		font-size: 40px;
	}
`;
