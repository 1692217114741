import * as React from 'react';
import styled from 'styled-components';
import { WebRouter } from './utils/WebRouter';

export const App: React.FC = () => {
	return (
		<AppBody>
			<WebRouter />
		</AppBody>
	);
};

const AppBody = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
