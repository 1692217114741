import * as React from 'react';
import {} from 'react-alert';
import styled from 'styled-components';
import { Text } from './parts';

export const AlertComponent: React.FC<any> = (props) => {
	const { options, message } = props;

	return (
		<AlertComponentBody error={options.type === 'error'}>
			<Text weight="semi-bold" color={options.type === 'error' ? '#FD5454' : '#4B4B4B'}>
				{message}
			</Text>
		</AlertComponentBody>
	);
};

const AlertComponentBody = styled.div<{ error: boolean }>`
	flex: 1;
	margin-top: 10px;
	background-color: white;
	/* box-shadow: 0px 2px 8px 4px #0000001d; */
	box-shadow: 0 3px 16px 5px #00000038;
	border-radius: 8px;
	margin-bottom: 30px;
	padding: 10px 20px;
`;
