import * as React from 'react';
import styled from 'styled-components';
import { Row, Spacer, Text } from '../../parts';

interface PRessViewProps {}

export const PRessView: React.FC<PRessViewProps> = (props) => {
	const {} = props;

	return (
		<PRessViewBody>
			<Text size={50} weight="bold">
				Press Inquiries, Contact:{' '}
			</Text>
			<Spacer size={50} />

			<Box>
				{/* TODO: FIX THIS */}
				<Row>
					<strong>Email:</strong>
					<Text>hayden@liverewadsgo.com</Text>
				</Row>

				<Row>
					<strong>Phone:</strong>
					<Text>+1 (949) 327 0242</Text>
				</Row>

				<Row>
					<strong>Address:</strong>
					<Text>300 Pacific Coast Highway, Suite 114</Text>
					<br />
					<Text>Huntington Beach, CA 92648</Text>
				</Row>

				{/* <Text>
					<strong>Email:</strong>hayden@liverewadsgo.com
				</Text>
				<Text>
					<strong>Phone:</strong>+1 (949) 327 0242
				</Text>
				<Text>
					<strong>Address:</strong>300 Pacific Coast Highway, Suite 114
				</Text>
				<Text>Huntington Beach, CA 92648</Text> */}
			</Box>

			{/* TODO: DOWNLOAD ASSETS FOR PRESS*/}

			<Spacer size={50} />
			<Spacer size={50} />
			<Spacer size={50} />
			<Spacer size={150} />
			<Spacer size={150} />
		</PRessViewBody>
	);
};

const PRessViewBody = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* text-align: center; */
	@media only screen and (min-width: 900px) {
		padding: unset;
		max-width: 900px;
		margin: 0 auto;
	}
`;

const Box = styled.div`
	strong {
		padding-right: 5px;
	}

	p {
		font-size: 18px;
	}
`;
