import * as React from 'react';
import styled from 'styled-components';
import handhsake_image from '../../assets/handshake_people-01.png';
import { BoxWrapper, Row, Spacer, Text } from '../../parts';

import image1 from '../../assets/phone_image_1.png';
import image2 from '../../assets/phone_image_2.png';

interface PartnershipViewProps {}

export const PartnershipView: React.FC<PartnershipViewProps> = (props) => {
	const {} = props;

	return (
		<PartnershipViewBody>
			<TopBanner>
				<BoxWrapper>
					<Spacer size={20} />
					<Text size={50} weight="bold" color="#1372ed">
						Partnership Opportunities
					</Text>
					<Spacer size={60} />
					<img src={handhsake_image} height="250" />
				</BoxWrapper>
			</TopBanner>
			<Spacer size={60} />
			<BoxWrapper>
				<Text center size={18}>
					Start giving your employees/customers the power of the Live Rewards mobile app.
					<br />
					<br />
					Inquire about unique partnership opportunities that can take your business to the next level.
				</Text>
				<Spacer size={50} />

				<ContactBox>
					<Text center size={18} weight="medium">
						Contact Us
					</Text>
					<Spacer size={5} />
					<EmailBtn onClick={() => window.location.assign('mailto:partnerships@liverewardsgo.com')}>partnerships@liverewardsgo.com</EmailBtn>
					<Spacer size={2} />
					<Text>+1 (877) 744 7606 </Text>
				</ContactBox>
				<Spacer size={80} />

				<Row>
					<img src={image1} height="480" />
					<Spacer size={20} />
					<img src={image2} height="480" />
				</Row>
			</BoxWrapper>

			<Spacer size={150} />
		</PartnershipViewBody>
	);
};

const PartnershipViewBody = styled.div``;

const TopBanner = styled.div``;
const EmailBtn = styled(Text)`
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`;

const ContactBox = styled.div`
	background-color: #e9e9e9;
	border-radius: 12px;
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
