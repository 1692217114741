import { ErrorPage } from '../screens/404/ErrorPage';
import { AboutView } from '../screens/about/AboutView';
import { Afterscreen } from '../screens/after/Afterscreen';
import { BusinessView } from '../screens/business/BusinessView';
import { PartnershipView } from '../screens/business/PartnershipView';
import { CancelScreen } from '../screens/cancel/CancelScreen';
import { ContactView } from '../screens/contact/ContactView';
import { MainScreen } from '../screens/home/Mainscreen';
import { PRessView } from '../screens/press/PressView';
import { PrivacyScreen } from '../screens/privacy/PrivacyScreen';
import { TermsScreen } from '../screens/terms/TermsScreen';
import { VerifyCancelScreen } from '../screens/verifycancel/VerifyCancelScreen';
import { CustompageView } from '../screens/CustomPage/CustompageView';

interface RouteConfig {
	path: string;
	comp?: React.FC;
	subRoutes?: RouteConfig[];
}

export const routes: RouteConfig[] = [
	{ path: '/', comp: MainScreen },
	{ path: '/after', comp: Afterscreen },
	{ path: '/cancel', comp: CancelScreen },
	{ path: '/cancel/verify', comp: VerifyCancelScreen },
	{ path: '/privacy', comp: PrivacyScreen },
	{ path: '/terms', comp: TermsScreen },
	{ path: '/contact', comp: ContactView },
	{ path: '/about', comp: AboutView },
	{ path: '/press', comp: PRessView },
	{ path: '/business', comp: BusinessView },
	{ path: '/partnership', comp: PartnershipView },
	{ path: '/:slug', comp: CustompageView },
];
