import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import { AlertComponent } from './AlertComponent';

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const AlertOptions = {
	// you can also just use 'bottom center'
	position: positions.TOP_CENTER,
	timeout: 2000,
	offset: '50px',

	// you can also just use 'scale'
	transition: transitions.FADE,
};

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AlertProvider template={AlertComponent} {...AlertOptions}>
				<QueryClientProvider client={queryClient}>
					<App />
				</QueryClientProvider>
			</AlertProvider>
		</BrowserRouter>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
