import * as React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Row, Spacer, Text, Icon } from '../../parts';

import phone1 from '../../assets/phone1.png';
import logo from '../../assets/logo_no_padding.png';
import playstorebadge from '../../parts/icon/svg/playstore-badge.svg';
import iosstorebadge from '../../parts/icon/svg/iosstore-badge.svg';

// import ReactPixel from 'react-facebook-pixel';

if (process.env.NODE_ENV === 'production') {
	// ReactPixel.init('3075038409445103');
	// ReactPixel.track('track', 'Purchase');
}

interface AfterscreenProps {}

export const Afterscreen: React.FC<AfterscreenProps> = (props) => {
	const {} = props;
	const QueryParams = useLocation();
	const [Failed, setFailed] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (QueryParams.search.includes('status')) {
			let status = QueryParams.search.split('status=')[1]?.split('&')[0];
			if (status === 'success') {
				console.log('success');

				// Open the store page if sucess
				if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
					window.location.href = 'https://play.google.com/store/apps/details?id=com.access.liverewards&hl=en&gl=US';
				}
				if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
					window.location.href = 'https://apps.apple.com/us/app/live-rewards/id1470069033';
				};

			} else {
				setFailed(true);
				console.log('failed');
			}
		}
	}, [QueryParams.search]);

	if (Failed) {
		return (
			<AfterscreenBody>
				<Spacer size={30} />
				<Row style={{ flexWrap: 'wrap' }}>
					<Text>Something went wrong with the payment, go </Text>
					<Link onClick={() => window.open(process.env.NODE_ENV === 'production' ? 'https://liverewardsgo.com' : 'http://localhost:3000', '_self')}>back</Link>
					<Text>to try again</Text>
				</Row>
				<Spacer size={15 * 8} />
			</AfterscreenBody>
		);
	}

	const starSize = 12;

	return (
		<AfterscreenBody>
			<Spacer size={40} />
			<AppAdContent>
				<LeftSection>
					<Row>
						<img src={logo} alt="logo" style={{ height: 70, marginRight: 15 }} />
						<div>
							<Row>
								<strong style={{ fontSize: 30 }}>LIVE</strong>
								<p style={{ fontSize: 30 }}>REWARDS</p>
							</Row>
							{/* <Row>
								<Icon name="star" size={starSize} color="#E6C315" />
								<Spacer size={5} />
								<Icon name="star" size={starSize} color="#E6C315" />
								<Spacer size={5} />
								<Icon name="star" size={starSize} color="#E6C315" />
								<Spacer size={5} />
								<Icon name="star" size={starSize} color="#E6C315" />
								<Spacer size={5} />
								<Icon name="star" size={starSize} color="#E6C315" />
							</Row> */}
						</div>
					</Row>

					<Spacer size={40} />

					<Row>
						<strong style={{ fontSize: 36, letterSpacing: -2, marginRight: 4 }}>DOWNLOAD</strong>
						<Text size={36} style={{ fontWeight: 300, letterSpacing: -2, whiteSpace: 'nowrap' }}>
							THE APP
						</Text>
					</Row>
					<p style={{ fontSize: 20, fontWeight: 500, letterSpacing: -0.8 }}>Get notified whe you're close to a deal</p>
					<Spacer size={50} />
					<div>
						<BadgeImage
							src={playstorebadge}
							alt="playstore-badge"
							style={{ width: 190 }}
							onClick={() => window.open('https://play.google.com/store/apps/details?id=com.access.liverewards&hl=en&gl=US')}
						/>
						<Spacer size={10} />
						<BadgeImage src={iosstorebadge} alt="iosstore-badge" style={{ width: 190 }} onClick={() => window.open('https://apps.apple.com/us/app/live-rewards/id1470069033')} />
					</div>

					<Spacer size={80} />
					<ClickLink onClick={() => window.open('https://liverewardstoday.com')}>www.liverewardstoday.com</ClickLink>
				</LeftSection>
				<Spacer size={80} />
				<img src={phone1} alt="phone1" style={{ height: 600 }} />
			</AppAdContent>
			<Spacer size={150} />
		</AfterscreenBody>
	);
};

const AfterscreenBody = styled.div`
	padding: 0px 40px;
	@media only screen and (min-width: 900px) {
		padding: none;
		max-width: 900px;
		margin: 0 auto;
	}
`;

const Link = styled.div`
	color: #1573ed;
	cursor: pointer;
	width: fit-content;
	font-weight: bold;
	padding: 0px 3px;
	text-decoration: underline;
`;

const ClickLink = styled.p`
	width: fit-content;
	cursor: pointer;
	font-weight: 700;
	color: #1573ed;
	font-size: 17px;

	transition: opacity 0.1s ease-in-out;
	&:hover {
		opacity: 0.5;
	}
`;

const BadgeImage = styled.img`
	cursor: pointer;
`;

const AppAdContent = styled(Row)`
	justify-content: space-between;

	@media only screen and (max-width: 700px) {
		flex-direction: column;
	}
`;

const LeftSection = styled.div`
	height: 600px;
	@media only screen and (max-width: 700px) {
		height: 470px;
	}
`;
