import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { Center, Text } from '../../parts';
import { request } from '../../utils';

interface VerifyCancelScreenProps {}

export const VerifyCancelScreen: React.FC<VerifyCancelScreenProps> = (props) => {
	const alert = useAlert();
	const [Succss, setSuccss] = useState(false);
	const [Loaded, setLoaded] = useState(false);

	const verifyCancellation = async (code: string): Promise<any> => {
		const a = await request('post', 'subscription/cancel/confirm', { code });
		if (a.success === false) {
			alert.error(a.message);
		} else {
			setSuccss(true);
			alert.success(a.message);
		}

		setLoaded(true);
	};

	useEffect(() => {
		verifyCancellation(window.location.search.split('code=')[1]?.split('&')[0]);
	}, []);

	return (
		<VerifyCancelScreenBody>
			{Loaded && (
				<Center>
					<Text>{Succss ? '.' : 'Something went wrong'}</Text>
				</Center>
			)}
		</VerifyCancelScreenBody>
	);
};

const VerifyCancelScreenBody = styled.div``;
