import Axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://liverewards_api.liverewardsgo.com/' : 'http://localhost:8080/';

// export const RequestError = event<{ message: string }>();

interface Config {
	headers?: any;
	newBaseUrl?: string;
	path?: string;
}

export const request = async (method: 'post' | 'get' | 'delete' | 'put', path: string, data?: any, c?: Config) => {
	try {
		const res = await Axios({
			method,
			url: c?.path ? c?.path + path : (c?.newBaseUrl || baseURL) + path,
			data,
			headers: c?.headers,
		});

		return res?.data;
	} catch (error) {
		console.log(error);
		// return res.data

		// alert there is an error
	}
};
