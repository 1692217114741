import * as React from 'react';
import styled from 'styled-components';
import { Row, Spacer, Text } from '../../parts';
import { request } from '../../utils';
import { useAlert } from 'react-alert';
import { useState } from 'react';

import wave from '../../assets/wave.svg';
import phones from '../../assets/phoness.png';

export const MainScreen: React.FC = () => {
	const alert = useAlert();
	const [Email, setEmail] = useState('');
	const [FirstName, setFirstName] = useState('');
	const [LastName, setLastName] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');
	const [WithCode, setWithCode] = useState(false);
	const [Plan, setPlan] = useState<'yearly' | 'monthly'>('monthly');

	const startTrial = async () => {
		if (!Email && !FirstName && !LastName) {
			setErrorMessage('All fields are required');
			return;
		}

		const req = await request('post', 'subscription/start' + (WithCode ? window.location.search?.split('code=')[1] : ''), {
			email: Email?.trimLeft()?.trimRight(),
			first_name: FirstName,
			last_name: LastName,
			subscription_plan: Plan,
		});

		// Check if failed
		if (req.success === false) {
			alert.error(req.message);
		} else {
			// window.location.assign('/after');
			window.location.assign(req.data.url);
		}
	};

	React.useEffect(() => {
		if (window.location.search?.split('code=')[1]) setWithCode(true);
	}, []);

	return (
		<>
			<WaveImg src={wave} alt={'wave'} />

			<MainScreenBody>
				<Spacer size={40} />

				<Box>
					<LeftSide>
						<Text size={45} weight="heavy" color={'#037DFC'}>
							Never pay full price again
						</Text>
						<Spacer size={30} />

						<Text size={30} weight="bold" style={{ letterSpacing: -1.2 }}>
							Unlimited access to offers, discounts, savings and more.
						</Text>
						<Spacer size={10} />
						<Text>Access to everything Live Rewards has to offer.</Text>

						<Spacer size={30} />

						{!WithCode && (
							<>
								<Row>
									{Plan === 'yearly' && (
										<Text color="gray" weight="semi-bold" style={{ marginRight: 5, textDecoration: 'line-through', fontStyle: 'italic' }}>
											$19.99
										</Text>
									)}
									<Text size={18} weight="bold" color="#1372ed" style={{ letterSpacing: -0.5 }}>
										${Plan === 'monthly' ? '1.99/Month' : '14.99/Yearly'}
									</Text>
								</Row>

								<Spacer size={5} />
								<Line />
								<Spacer size={10} />

								<BigBox>
									<PlanBox value={Plan === 'monthly'} onClick={() => setPlan(Plan === 'yearly' ? 'monthly' : 'yearly')}>
										Monthly
									</PlanBox>
									<PlanBox value={Plan === 'yearly'} onClick={() => setPlan(Plan === 'yearly' ? 'monthly' : 'yearly')} style={{ flexDirection: 'column' }}>
										Yearly
									</PlanBox>
								</BigBox>
							</>
						)}

						<Spacer size={20} />
						<Input value={Email} placeholder="Email" onChange={(v) => setEmail(v.target.value)} />
						<Input value={FirstName} placeholder="First name" onChange={(v) => setFirstName(v.target.value)} />
						<Input value={LastName} placeholder="Last name" onChange={(v) => setLastName(v.target.value)} />
						<br />
						<Button onClick={async () => startTrial()} disabled={!Email || !FirstName || !LastName}>
							Get started
						</Button>
						<Spacer size={10} />
						<Text size={14}>
							You agree with the <a href="/terms">terms of service</a> and <a href="/privacy">privacy & policy</a>
						</Text>
					</LeftSide>
					<ImageArea>
						<Img src={phones} alt="phone2" height={750} style={{}} />
					</ImageArea>
				</Box>
				<Spacer size={250} />
			</MainScreenBody>
		</>
	);
};

const MainScreenBody = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media only screen and (min-width: 1200px) {
		padding: 0px;
		max-width: 1160px;
		margin: 0 auto;
	}
`;

const WaveImg = styled.img`
	width: 100%;
	max-width: 100%;
	margin-top: -500px;

	@media only screen and (max-width: 1700px) {
		margin-top: -480px;
	}
	@media only screen and (max-width: 1600px) {
		margin-top: -450px;
	}
	@media only screen and (max-width: 1500px) {
		margin-top: -420px;
	}
	@media only screen and (max-width: 1400px) {
		margin-top: -390px;
	}
	@media only screen and (max-width: 1300px) {
		margin-top: -360px;
	}
	@media only screen and (max-width: 1200px) {
		margin-top: -330px;
	}
	@media only screen and (max-width: 1100px) {
		margin-top: -300px;
	}
	@media only screen and (max-width: 1000px) {
		margin-top: -270px;
	}
	@media only screen and (max-width: 900px) {
		margin-top: -240px;
	}
	@media only screen and (max-width: 800px) {
		margin-top: -210px;
	}
	@media only screen and (max-width: 700px) {
		margin-top: -180px;
	}
	@media only screen and (max-width: 600px) {
		margin-top: -150px;
	}
	@media only screen and (max-width: 500px) {
		margin-top: -120px;
	}
	@media only screen and (max-width: 400px) {
		margin-top: -104px;
	}
`;

const LeftSide = styled.div`
	display: flex;
	flex-direction: column;
	width: 700px;

	button {
		@media only screen and (max-width: 1100px) {
			width: 100%;
		}
	}

	@media only screen and (max-width: 650px) {
		max-width: 300px;
	}
`;

const Box = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media only screen and (max-width: 1100px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const Line = styled.div`
	height: 5px;
	width: 100%;
	margin: 10px 0px;
	background-color: #e9e9e9;
	border-radius: 15px;
`;

const ImageArea = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
`;

const Img = styled.img`
	@media only screen and (max-width: 500px) {
		max-width: 290px;
		height: auto;
	}
`;

const Button = styled.button`
	background-color: #1372ed;
	color: white;
	font-weight: 700;
	font-size: 18px;
	padding: 10px;
	width: calc(100% + 20px);
	border: none;
	border-radius: 10px;
	cursor: pointer;
	height: 50px;

	transition: opacity 0.1s ease-in-out;

	&:hover {
		opacity: 0.6;
	}

	&:active {
		opacity: 0.4;
	}

	&:disabled {
		opacity: 0.5;
		cursor: auto;
	}
`;

const Input = styled.input`
	padding: 14px;
	font-weight: 400;
	font-size: 14px;
	font-family: bozon;
	border-radius: 10px;
	border: solid 2px #f1f1f1;
	background: #fbfbfb;
	height: 25px;
	width: calc(100% - 14px);
	outline: none;
	margin-bottom: 8px;

	transition: border-color 0.2s ease-in-out;

	&:focus {
		border-color: #1571ed;
	}

	&::placeholder {
		font-family: 'Source Sans Pro', sans-serif;
		color: #ababab;
		font-weight: 600;
	}
`;

const BigBox = styled(Row)`
	padding: 6px;
	width: 100%;
	background-color: #f3f3f3;
	border-radius: 8px;
`;

const PlanBox = styled(Text).attrs({ weight: 'semi-bold' })<{ value: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	height: 40px;
	width: 100%;
	${({ value }) => (value ? 'background-color: white;' : '')}
	cursor: pointer;
	border: solid 2px transparent;

	transition: background-color 0.1s ease-in-out, border 0.1s ease-in-out;

	:nth-child(2) {
		${({ value }) => (value ? 'border: solid 2px #1372ed;' : '')}
	}
`;
