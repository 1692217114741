import * as React from 'react';interface IconIosstoreBadgeProps {color?: any;style?: any;}export const IosstoreBadge: React.FC<IconIosstoreBadgeProps> = (props) => {return (<svg style={props.style} width="317" height="106" viewBox="0 0 317 106" fill={props.color} xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0)">
<path d="M291.756 0.0003445H25.2582C24.2867 0.0003445 23.327 0.0003445 22.3581 0.0056445C21.5471 0.0109445 20.7426 0.026341 19.9238 0.0392995C18.1449 0.0602393 16.3703 0.216787 14.6153 0.507581C12.8627 0.804706 11.165 1.36487 9.57967 2.16913C7.99625 2.98019 6.54945 4.03409 5.29173 5.29258C4.02741 6.54753 2.9735 7.99809 2.17053 9.58847C1.36534 11.1757 0.807018 12.8765 0.514849 14.6323C0.219903 16.3858 0.0611895 18.1595 0.0401336 19.9376C0.0155501 20.7501 0.012954 21.5652 0 22.3778V83.6306C0.012954 84.4535 0.0155501 85.2506 0.0401336 86.0737C0.061196 87.8516 0.219909 89.6253 0.514849 91.3787C0.806211 93.1355 1.36456 94.8373 2.17053 96.4251C2.97313 98.0103 4.02719 99.455 5.29173 100.703C6.54468 101.967 7.99242 103.022 9.57967 103.827C11.165 104.633 12.8625 105.196 14.6153 105.498C16.3706 105.787 18.145 105.943 19.9238 105.967C20.7426 105.985 21.5471 105.995 22.3581 105.995C23.3269 106 24.2868 106 25.2582 106H291.756C292.708 106 293.676 106 294.628 105.995C295.435 105.995 296.263 105.985 297.07 105.967C298.845 105.944 300.616 105.788 302.368 105.498C304.126 105.194 305.83 104.631 307.423 103.827C309.009 103.021 310.455 101.967 311.707 100.703C312.968 99.4501 314.025 98.0065 314.837 96.4251C315.637 94.8361 316.19 93.1344 316.477 91.3787C316.773 89.625 316.937 87.8518 316.969 86.0737C316.979 85.2506 316.979 84.4535 316.979 83.6306C317 82.6679 317 81.7104 317 80.7321V25.2711C317 24.3006 317 23.3379 316.979 22.3778C316.979 21.5652 316.979 20.7501 316.969 19.9375C316.937 18.1593 316.773 16.3859 316.477 14.6322C316.189 12.8774 315.636 11.1767 314.837 9.58836C313.202 6.40061 310.609 3.80581 307.423 2.16889C305.83 1.3666 304.126 0.806582 302.368 0.507343C300.617 0.215267 298.845 0.0586651 297.07 0.0389285C296.263 0.0259965 295.435 0.0104675 294.628 0.0053C293.676 0 292.708 0 291.756 0V0.0003445Z" fill={props.color}/>
<path d="M22.3714 103.681C21.5642 103.681 20.7766 103.671 19.9758 103.653C18.3169 103.631 16.6619 103.487 15.0243 103.221C13.4974 102.958 12.0182 102.468 10.6355 101.769C9.26548 101.075 8.01594 100.166 6.93471 99.0749C5.83783 97.997 4.92463 96.7469 4.23129 95.3741C3.53032 93.9924 3.0452 92.5113 2.79284 90.9826C2.52032 89.3399 2.37287 87.6788 2.35177 86.0138C2.33498 85.4549 2.31299 83.5941 2.31299 83.5941V22.3776C2.31299 22.3776 2.33641 20.5454 2.3519 20.0071C2.3721 18.3447 2.5187 16.6862 2.79041 15.0461C3.04323 13.5131 3.52872 12.0278 4.23008 10.6415C4.92086 9.26965 5.829 8.01863 6.91927 6.93695C8.00831 5.84499 9.26185 4.9307 10.6342 4.2274C12.0137 3.53015 13.4901 3.04424 15.014 2.78596C16.6569 2.51716 18.3177 2.37184 19.9823 2.3512L22.3727 2.31885H294.615L297.034 2.3525C298.683 2.37211 300.329 2.51614 301.957 2.78337C303.496 3.04488 304.988 3.53418 306.383 4.23517C309.133 5.65251 311.37 7.89488 312.781 10.648C313.471 12.0247 313.949 13.4979 314.199 15.0176C314.474 16.6712 314.627 18.3427 314.659 20.0187C314.667 20.7692 314.667 21.5753 314.667 22.3776C314.688 23.3713 314.688 24.3172 314.688 25.2708V80.7318C314.688 81.6946 314.688 82.634 314.667 83.5811C314.667 84.4429 314.667 85.2323 314.656 86.0448C314.625 87.6909 314.474 89.3326 314.204 90.9566C313.956 92.4964 313.474 93.989 312.773 95.3821C312.075 96.74 311.167 97.9793 310.083 99.0542C309.001 100.151 307.749 101.066 306.376 101.764C304.984 102.469 303.495 102.96 301.957 103.221C300.319 103.488 298.664 103.633 297.005 103.653C296.229 103.671 295.417 103.681 294.628 103.681L291.756 103.687L22.3714 103.681Z" fill={props.color}/>
<path d="M65.6148 53.7971C65.6433 51.5853 66.2306 49.4166 67.3221 47.4928C68.4136 45.569 69.9738 43.9527 71.8576 42.7942C70.6609 41.0845 69.0822 39.6775 67.2468 38.685C65.4114 37.6924 63.37 37.1416 61.2846 37.0763C56.8362 36.6092 52.5236 39.7391 50.2568 39.7391C47.9461 39.7391 44.4559 37.1227 40.6976 37.2001C38.2666 37.2786 35.8974 37.9858 33.8208 39.2526C31.7443 40.5195 30.0312 42.3028 28.8484 44.4289C23.7251 53.3022 27.5466 66.343 32.4544 73.5155C34.9098 77.0277 37.7795 80.9509 41.5344 80.8118C45.2089 80.6593 46.5812 78.4679 51.0166 78.4679C55.4108 78.4679 56.6983 80.8118 60.5294 80.7233C64.4723 80.6593 66.9564 77.1955 69.3257 73.6501C71.0899 71.1475 72.4475 68.3817 73.3481 65.4549C71.0575 64.4858 69.1026 62.8635 67.7274 60.7904C66.3522 58.7173 65.6175 56.2851 65.6148 53.7971V53.7971Z" fill={props.color}/>
<path d="M58.3787 32.359C60.5285 29.7774 61.5877 26.4591 61.3312 23.1089C58.0468 23.454 55.0129 25.0243 52.834 27.5069C51.7687 28.7197 50.9528 30.1306 50.4329 31.6591C49.9131 33.1875 49.6994 34.8035 49.8042 36.4145C51.447 36.4315 53.0723 36.0753 54.5575 35.3728C56.0428 34.6703 57.3493 33.6398 58.3787 32.359Z" fill={props.color}/>
<path d="M112.062 71.9203H99.523L96.5118 80.8149H91.2007L103.078 47.9072H108.596L120.473 80.8149H115.071L112.062 71.9203ZM100.822 67.8159H110.761L105.861 53.3807H105.724L100.822 67.8159Z" fill={props.color}/>
<path d="M146.123 68.8198C146.123 76.2755 142.133 81.0657 136.113 81.0657C134.589 81.1455 133.072 80.7941 131.737 80.0517C130.403 79.3094 129.304 78.2061 128.567 76.8681H128.453V88.7517H123.53V56.8224H128.296V60.8129H128.386C129.157 59.4813 130.274 58.3837 131.619 57.6369C132.964 56.89 134.486 56.5217 136.023 56.5713C142.11 56.5713 146.123 61.3848 146.123 68.8198ZM141.062 68.8198C141.062 63.9624 138.553 60.7689 134.724 60.7689C130.963 60.7689 128.433 64.0296 128.433 68.8198C128.433 73.654 130.963 76.8915 134.724 76.8915C138.553 76.8915 141.062 73.7213 141.062 68.8198Z" fill={props.color}/>
<path d="M172.52 68.8198C172.52 76.2755 168.531 81.0657 162.511 81.0657C160.986 81.1455 159.469 80.7941 158.135 80.0517C156.8 79.3094 155.702 78.206 154.965 76.8681H154.851V88.7517H149.928V56.8223H154.693V60.8129H154.784C155.554 59.4813 156.671 58.3837 158.016 57.6368C159.361 56.89 160.883 56.5217 162.42 56.5713C168.508 56.5713 172.52 61.3848 172.52 68.8198ZM167.46 68.8198C167.46 63.9624 164.95 60.7689 161.122 60.7689C157.36 60.7689 154.83 64.0296 154.83 68.8198C154.83 73.654 157.36 76.8914 161.122 76.8914C164.951 76.8914 167.46 73.7213 167.46 68.8198H167.46Z" fill={props.color}/>
<path d="M189.967 71.6459C190.332 74.9092 193.501 77.0519 197.831 77.0519C201.981 77.0519 204.966 74.9091 204.966 71.9667C204.966 69.4124 203.166 67.883 198.902 66.8349L194.639 65.8075C188.598 64.3479 185.794 61.5219 185.794 56.9362C185.794 51.2583 190.74 47.3584 197.764 47.3584C204.715 47.3584 209.48 51.2583 209.641 56.9362H204.671C204.374 53.6521 201.66 51.6698 197.694 51.6698C193.728 51.6698 191.014 53.6755 191.014 56.5946C191.014 58.9211 192.748 60.2901 196.988 61.3381L200.612 62.2283C207.362 63.8251 210.166 66.5372 210.166 71.3506C210.166 77.5072 205.264 81.3632 197.466 81.3632C190.171 81.3632 185.245 77.5978 184.927 71.6456L189.967 71.6459Z" fill={props.color}/>
<path d="M220.791 51.1445V56.8224H225.352V60.7223H220.791V73.949C220.791 76.0038 221.704 76.9613 223.709 76.9613C224.251 76.9519 224.791 76.9139 225.329 76.8474V80.724C224.427 80.8925 223.511 80.9688 222.594 80.9518C217.738 80.9518 215.845 79.1273 215.845 74.4743V60.7223H212.357V56.8224H215.845V51.1445H220.791Z" fill={props.color}/>
<path d="M227.993 68.8198C227.993 61.2709 232.438 56.5273 239.368 56.5273C246.322 56.5273 250.746 61.2708 250.746 68.8198C250.746 76.3894 246.345 81.1123 239.368 81.1123C232.394 81.1123 227.993 76.3894 227.993 68.8198ZM245.73 68.8198C245.73 63.6415 243.357 60.5852 239.368 60.5852C235.379 60.5852 233.009 63.6647 233.009 68.8198C233.009 74.0189 235.379 77.0519 239.368 77.0519C243.357 77.0519 245.729 74.0189 245.729 68.8198H245.73Z" fill={props.color}/>
<path d="M254.805 56.8226H259.5V60.9063H259.614C259.932 59.6309 260.679 58.5037 261.729 57.7143C262.78 56.9249 264.07 56.5214 265.383 56.5716C265.95 56.5696 266.516 56.6312 267.07 56.7553V61.3617C266.353 61.1428 265.606 61.0423 264.858 61.0642C264.143 61.0351 263.43 61.1613 262.768 61.4339C262.106 61.7066 261.511 62.1193 261.023 62.6437C260.536 63.1681 260.168 63.7918 259.944 64.472C259.721 65.1521 259.647 65.8727 259.728 66.5841V80.8149H254.805L254.805 56.8226Z" fill={props.color}/>
<path d="M289.768 73.7679C289.106 78.1233 284.866 81.1123 279.441 81.1123C272.464 81.1123 268.133 76.436 268.133 68.9337C268.133 61.4081 272.487 56.5273 279.234 56.5273C285.87 56.5273 290.043 61.0872 290.043 68.3618V70.0491H273.103V70.3467C273.025 71.2296 273.135 72.1192 273.427 72.9561C273.719 73.7931 274.186 74.5584 274.796 75.2011C275.406 75.8439 276.146 76.3494 276.966 76.6841C277.787 77.0188 278.669 77.1751 279.555 77.1425C280.718 77.2515 281.886 76.982 282.884 76.3739C283.882 75.7659 284.657 74.8519 285.094 73.7678L289.768 73.7679ZM273.126 66.6072H285.117C285.161 65.8132 285.041 65.0187 284.763 64.2735C284.486 63.5283 284.058 62.8485 283.506 62.2767C282.953 61.705 282.289 61.2536 281.553 60.9509C280.818 60.6482 280.029 60.5008 279.234 60.5179C278.432 60.5131 277.638 60.6672 276.896 60.9712C276.154 61.2753 275.48 61.7233 274.912 62.2895C274.344 62.8556 273.894 63.5286 273.587 64.2697C273.281 65.0108 273.124 65.8052 273.126 66.6072V66.6072Z" fill={props.color}/>
<path d="M100.205 23.1371C101.237 23.063 102.272 23.219 103.237 23.5938C104.202 23.9686 105.071 24.5528 105.782 25.3044C106.494 26.0561 107.03 26.9563 107.351 27.9401C107.673 28.924 107.772 29.967 107.642 30.9939C107.642 36.0455 104.913 38.9492 100.205 38.9492H94.4951V23.1371H100.205ZM96.9502 36.713H99.9304C100.668 36.7571 101.406 36.6362 102.091 36.3589C102.776 36.0817 103.39 35.6552 103.89 35.1104C104.389 34.5657 104.761 33.9164 104.977 33.2099C105.194 32.5034 105.251 31.7573 105.143 31.0261C105.243 30.2978 105.181 29.5564 104.96 28.8551C104.74 28.1537 104.368 27.5098 103.87 26.9693C103.371 26.4289 102.76 26.0054 102.079 25.7292C101.398 25.453 100.664 25.3309 99.9304 25.3716H96.9502V36.713Z" fill={props.color}/>
<path d="M110.415 32.977C110.34 32.1929 110.43 31.4017 110.679 30.6542C110.927 29.9067 111.329 29.2194 111.859 28.6365C112.388 28.0535 113.034 27.5877 113.754 27.269C114.474 26.9503 115.253 26.7856 116.041 26.7856C116.828 26.7856 117.607 26.9503 118.327 27.269C119.047 27.5877 119.693 28.0535 120.223 28.6365C120.752 29.2194 121.154 29.9067 121.403 30.6542C121.651 31.4017 121.741 32.1929 121.666 32.977C121.743 33.762 121.654 34.5543 121.406 35.303C121.158 36.0517 120.756 36.7402 120.226 37.3243C119.697 37.9084 119.051 38.3752 118.33 38.6946C117.609 39.014 116.829 39.179 116.041 39.179C115.252 39.179 114.473 39.014 113.752 38.6946C113.031 38.3752 112.385 37.9084 111.855 37.3243C111.325 36.7402 110.923 36.0517 110.675 35.303C110.427 34.5543 110.339 33.762 110.415 32.977V32.977ZM119.245 32.977C119.245 30.3905 118.083 28.8778 116.045 28.8778C113.998 28.8778 112.847 30.3905 112.847 32.9771C112.847 35.5844 113.998 37.0853 116.045 37.0853C118.083 37.0853 119.245 35.574 119.245 32.977H119.245Z" fill={props.color}/>
<path d="M136.622 38.9489H134.18L131.714 30.1604H131.528L129.073 38.9489H126.654L123.366 27.0161H125.754L127.891 36.1215H128.067L130.519 27.0161H132.778L135.23 36.1215H135.416L137.543 27.0161H139.897L136.622 38.9489Z" fill={props.color}/>
<path d="M142.663 27.016H144.929V28.9116H145.105C145.403 28.2308 145.906 27.6601 146.545 27.279C147.183 26.8979 147.924 26.7254 148.664 26.7857C149.245 26.742 149.828 26.8296 150.37 27.0418C150.912 27.2541 151.399 27.5856 151.795 28.0118C152.192 28.4381 152.488 28.9481 152.66 29.5041C152.833 30.0601 152.878 30.6479 152.793 31.2239V38.9486H150.439V31.8153C150.439 29.8976 149.606 28.944 147.865 28.944C147.471 28.9256 147.078 28.9927 146.712 29.1407C146.346 29.2886 146.017 29.5139 145.746 29.8012C145.476 30.0884 145.27 30.4307 145.144 30.8047C145.019 31.1787 144.975 31.5755 145.017 31.9679V38.9488H142.663L142.663 27.016Z" fill={props.color}/>
<path d="M156.544 22.3579H158.899V38.9489H156.544V22.3579Z" fill={props.color}/>
<path d="M162.171 32.9773C162.096 32.193 162.185 31.4018 162.434 30.6543C162.683 29.9068 163.085 29.2195 163.614 28.6365C164.144 28.0535 164.79 27.5878 165.51 27.269C166.23 26.9503 167.009 26.7856 167.797 26.7856C168.584 26.7856 169.363 26.9503 170.083 27.269C170.803 27.5878 171.449 28.0535 171.979 28.6365C172.509 29.2195 172.911 29.9068 173.159 30.6543C173.408 31.4018 173.498 32.193 173.423 32.9773C173.499 33.7623 173.41 34.5546 173.162 35.3033C172.914 36.052 172.512 36.7405 171.983 37.3246C171.453 37.9086 170.807 38.3754 170.086 38.6948C169.365 39.0142 168.585 39.1792 167.797 39.1792C167.008 39.1792 166.228 39.0142 165.508 38.6948C164.787 38.3754 164.141 37.9086 163.611 37.3246C163.081 36.7405 162.679 36.052 162.431 35.3033C162.183 34.5546 162.094 33.7623 162.171 32.9773V32.9773ZM171 32.9773C171 30.3907 169.838 28.8781 167.8 28.8781C165.754 28.8781 164.602 30.3907 164.602 32.9773C164.602 35.5846 165.754 37.0856 167.8 37.0856C169.838 37.0855 171 35.5742 171 32.9773H171Z" fill={props.color}/>
<path d="M175.901 35.5741C175.901 33.4261 177.5 32.1879 180.338 32.0119L183.569 31.8255V30.7956C183.569 29.5353 182.736 28.8236 181.127 28.8236C179.813 28.8236 178.902 29.3062 178.641 30.1499H176.362C176.602 28.1003 178.53 26.7856 181.236 26.7856C184.226 26.7856 185.913 28.2749 185.913 30.7956V38.9487H183.647V37.2718H183.461C183.083 37.8733 182.552 38.3637 181.922 38.6928C181.293 39.022 180.587 39.1781 179.878 39.1453C179.377 39.1974 178.871 39.144 178.392 38.9884C177.913 38.8329 177.472 38.5787 177.097 38.2422C176.723 37.9057 176.423 37.4945 176.217 37.0349C176.011 36.5754 175.903 36.0777 175.901 35.5741V35.5741ZM183.569 34.5545V33.5568L180.656 33.7432C179.014 33.8531 178.268 34.4121 178.268 35.4641C178.268 36.5381 179.2 37.163 180.48 37.163C180.856 37.201 181.235 37.1631 181.595 37.0516C181.955 36.9401 182.289 36.7572 182.578 36.5138C182.866 36.2704 183.102 35.9715 183.272 35.6349C183.443 35.2983 183.544 34.9309 183.569 34.5545V34.5545Z" fill={props.color}/>
<path d="M189.007 32.9774C189.007 29.2068 190.945 26.8182 193.958 26.8182C194.704 26.7838 195.444 26.9624 196.091 27.3332C196.739 27.7039 197.268 28.2514 197.616 28.9117H197.792V22.3579H200.146V38.9489H197.891V37.0636H197.704C197.329 37.7194 196.782 38.2601 196.122 38.6271C195.461 38.9941 194.713 39.1734 193.958 39.1455C190.924 39.1457 189.007 36.757 189.007 32.9774ZM191.439 32.9774C191.439 35.5083 192.631 37.0313 194.626 37.0313C196.61 37.0313 197.836 35.4864 197.836 32.9877C197.836 30.5007 196.597 28.9338 194.626 28.9338C192.644 28.9338 191.439 30.4671 191.439 32.9774H191.439Z" fill={props.color}/>
<path d="M209.886 32.977C209.811 32.1929 209.901 31.4017 210.15 30.6542C210.398 29.9067 210.8 29.2194 211.33 28.6365C211.86 28.0535 212.505 27.5877 213.225 27.269C213.946 26.9503 214.724 26.7856 215.512 26.7856C216.299 26.7856 217.078 26.9503 217.798 27.269C218.518 27.5877 219.164 28.0535 219.694 28.6365C220.223 29.2194 220.625 29.9067 220.874 30.6542C221.123 31.4017 221.212 32.1929 221.137 32.977C221.214 33.762 221.125 34.5543 220.877 35.303C220.629 36.0517 220.227 36.7402 219.698 37.3243C219.168 37.9084 218.522 38.3752 217.801 38.6946C217.08 39.014 216.3 39.179 215.512 39.179C214.723 39.179 213.944 39.014 213.223 38.6946C212.502 38.3752 211.856 37.9084 211.326 37.3243C210.796 36.7402 210.395 36.0517 210.147 35.303C209.899 34.5543 209.81 33.762 209.886 32.977V32.977ZM218.716 32.977C218.716 30.3905 217.554 28.8778 215.516 28.8778C213.469 28.8778 212.318 30.3905 212.318 32.9771C212.318 35.5844 213.47 37.0853 215.516 37.0853C217.554 37.0853 218.716 35.574 218.716 32.977Z" fill={props.color}/>
<path d="M224.296 27.016H226.563V28.9116H226.739C227.037 28.2308 227.54 27.6601 228.178 27.279C228.816 26.8979 229.558 26.7254 230.298 26.7857C230.879 26.742 231.461 26.8296 232.003 27.0418C232.545 27.2541 233.033 27.5856 233.429 28.0118C233.826 28.4381 234.121 28.9481 234.294 29.5041C234.467 30.0601 234.512 30.6479 234.427 31.2239V38.9486H232.073V31.8153C232.073 29.8976 231.24 28.944 229.499 28.944C229.105 28.9256 228.711 28.9927 228.346 29.1407C227.98 29.2886 227.651 29.5139 227.38 29.8012C227.109 30.0884 226.904 30.4307 226.778 30.8047C226.652 31.1787 226.609 31.5755 226.651 31.9679V38.9488H224.296V27.016Z" fill={props.color}/>
<path d="M247.729 24.0454V27.0706H250.314V29.0543H247.729V35.1902C247.729 36.4401 248.244 36.9875 249.416 36.9875C249.716 36.9865 250.016 36.9683 250.314 36.9331V38.8947C249.891 38.9704 249.463 39.0106 249.033 39.015C246.415 39.015 245.373 38.0938 245.373 35.7931V29.0542H243.479V27.0705H245.373V24.0454H247.729Z" fill={props.color}/>
<path d="M253.529 22.3579H255.863V28.9338H256.049C256.362 28.2466 256.879 27.6726 257.53 27.2901C258.181 26.9075 258.934 26.7351 259.686 26.7962C260.264 26.7647 260.841 26.8612 261.377 27.0786C261.913 27.296 262.394 27.629 262.786 28.0539C263.179 28.4788 263.472 28.9851 263.647 29.5366C263.821 30.0882 263.871 30.6714 263.794 31.2447V38.9489H261.438V31.8257C261.438 29.9197 260.55 28.9544 258.887 28.9544C258.482 28.9212 258.075 28.9769 257.695 29.1175C257.314 29.2581 256.968 29.4802 256.682 29.7684C256.396 30.0565 256.176 30.4037 256.038 30.7857C255.9 31.1676 255.848 31.5751 255.884 31.9797V38.9488H253.529L253.529 22.3579Z" fill={props.color}/>
<path d="M277.521 35.727C277.202 36.8176 276.509 37.7609 275.564 38.392C274.619 39.0231 273.482 39.3018 272.353 39.1792C271.567 39.2 270.786 39.0494 270.064 38.738C269.342 38.4265 268.697 37.9616 268.172 37.3756C267.648 36.7896 267.257 36.0965 267.028 35.3444C266.798 34.5922 266.735 33.7991 266.842 33.0201C266.738 32.2386 266.802 31.4438 267.031 30.6894C267.26 29.935 267.649 29.2387 268.17 28.6476C268.692 28.0564 269.334 27.5843 270.054 27.2631C270.774 26.9419 271.554 26.7791 272.342 26.7858C275.661 26.7858 277.664 29.0542 277.664 32.8013V33.6231H269.241V33.7551C269.204 34.193 269.259 34.6337 269.402 35.0491C269.545 35.4645 269.774 35.8453 270.073 36.1672C270.372 36.489 270.735 36.7448 271.139 36.9181C271.543 37.0914 271.978 37.1784 272.417 37.1736C272.98 37.2412 273.551 37.1397 274.056 36.8821C274.562 36.6245 274.979 36.2224 275.255 35.7269L277.521 35.727ZM269.24 31.8814H275.266C275.295 31.4809 275.241 31.0786 275.106 30.7004C274.971 30.3222 274.758 29.9765 274.482 29.6853C274.205 29.3942 273.871 29.1642 273.5 29.01C273.129 28.8559 272.731 28.781 272.329 28.7902C271.922 28.7851 271.518 28.8615 271.141 29.0151C270.764 29.1686 270.421 29.3962 270.133 29.6843C269.845 29.9723 269.618 30.3152 269.465 30.6925C269.311 31.0699 269.235 31.4741 269.241 31.8814H269.24Z" fill={props.color}/>
</g>
<defs>
<clipPath id="clip0">
<rect width="317" height="106" fill={props.color}/>
</clipPath>
</defs>
</svg>
);};