import * as React from 'react';
import styled from 'styled-components';
import { routes } from './routes';
import { Footer } from '../components/Footer';
import { Header } from '../components/Headers';
import { Route, Routes } from 'react-router-dom';

export const WebRouter: React.FC = () => {
	return (
		<Container>
			<Header />
			<Routes>
				{routes.map((v, i) => {
					if (v?.subRoutes) {
						if (v.comp) return v.subRoutes.map((v2, i2) => <Route key={i * i2} path={v.path + v2.path} element={<v2.comp />} />);
					} else {
						return <Route key={i} path={v.path} element={<v.comp />} />;
					}
				})}
			</Routes>
			<Footer />
		</Container>
	);
};

const Container = styled.div`
	height: 100%;
	background-color: ${({ theme }) => theme.background};
	color: ${({ theme }) => theme.text};
`;
