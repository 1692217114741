import * as React from 'react';
import styled from 'styled-components';
import { Spacer } from '../../parts';

interface TermSceenProps {}

export const TermsScreen: React.FC<TermSceenProps> = (props) => {
	const {} = props;

	return (
		<TermSceenBody>
			<h1>Terms and Conditions</h1>
			<p>
				<strong>THE AGREEMENT</strong>: The use of this website and services on this website and mobile application provided by Live Rewards (here in after referred to as
				"Website") aresubject to the following Terms & Conditions, all parts and sub-parts of which are specifically incorporated by reference here. This Agreement shall govern the
				use ofall pages on this website (here inafter collectively referred to as "Website") and any services provided by or on this Website ("Services").
			</p>
			<br />

			<h3>1) DEFINITIONS</h3>
			<p>“Agreement” denotes to this Terms and Conditions and the Privacy Policy and other documents provided to you by the Website;</p>
			<br />
			<p>
				<strong>“We”</strong>, <strong>“us”</strong> and <strong>“our”</strong> are references to LIVE REWARDS;
			</p>
			<br />
			<p>
				<strong>“User”</strong>, <strong>“You”</strong> and <strong>“your”</strong> denotes the person who is accessing the website fortaking or availing any service from us. User
				shall include the company, partnership,sole trader, person, body corporate or association taking services of this Website;
			</p>
			<br />
			<p>
				<strong>”Website”</strong> shall mean and include <strong>Live Rewards</strong> and any successor Website ofthe Company or any of its affiliates;
			</p>
			<br />
			<p>
				<strong>Parties</strong>: Collectively, the parties to this Agreement (We and You) will be referred toas Parties.
			</p>
			<br />

			<h3>2) ASSENT & ACCEPTANCE</h3>
			<p>
				By using the Website, you warrant that you have read and reviewed this Agreement and that you agree to be bound by it. If you do not agree to be bound by this Agreement,
				please leave the Website immediately. We only agree to provide the users of this Website and Services to you if you assent to this Agreement.
			</p>
			<br />

			<h3>3) AGE RESTRICTION</h3>
			<p>
				You must be at least 13 (Thirteen) years of age to use this Website or any Services contained herein. By using this Website, you represent and warrant that you are at least
				13 years of age and may legally agree to this Agreement. We assume no responsibility or liability for any misrepresentation of your age.
			</p>
			<br />

			<h3>4) GENERAL CONDITION</h3>
			<ul>
				<li>We do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us.</li>
				<li>
					We make material changes to these terms and conditions from time to time, we may notify you either by prominently posting a notice of such changes or via email
					communication.
				</li>
				<li>
					The website is licensed to you on a limited, non-exclusive, non-transferable, non-sublicensable basis, solely to be used in connection with the Service for your private,
					personal, non-commercial use, subject to all the terms and conditions of this Agreement as they apply to the Service.
				</li>
			</ul>
			<br />

			<h3>5) LICENSE TO USE WEBSITE</h3>
			<p>
				We may provide you with certain information as a result of your use of the Website or Services. Such information may include but is not limited to, documentation, data, or
				information developed by us, and other materials which may assist in your use of the Website or Services ("Our Materials"). Subject to this Agreement, we grant you a
				non-exclusive, limited, non-transferable, and revocable license to use Our Materials solely in connection with your use of the Website and Services. Our Materials may not
				be used for any other purpose, and this license terminates upon your cessation of use of the Website or Services or at the termination of this Agreement.
			</p>
			<br />

			<h3>6) USER CONTENT</h3>
			<p>
				Content Responsibility. <br />
				The website permits you to share content, post comments, feedback, etc. but you are solely responsible for the content posted by you. You represent that you have required
				permission to use the content.
				<br /> When posting content to the website, please do not post content that:
			</p>
			<ul>
				<li>
					contains ill-mannered, profane, abusive, racist or hateful language or expressions, text, photographs or illustrations that are pornographic or in poor taste,
					inflammatory attacks of a personal, racial or religious nature;
				</li>
				<li>
					is defamatory, threatening, disparaging, grossly inflammatory, false, misleading, fraudulent, inaccurate, unfair, contains gross exaggeration or unsubstantiated claims;
				</li>
				<li>violates the privacy rights of any third party, is unreasonably harmful or offensive to any individual or community;</li>
				<li>
					discriminates on the grounds of race, religion, national origin, gender, age, marital status, sexual orientation or disability, or refers to such matters in any manner
					prohibited by law;
				</li>
				<li>violates or inappropriately encourages the violation of any municipal, state, federal, or international law, rule, regulation, or ordinance;</li>
				<li>
					uses or attempts to use another's account, password, service, or system except as expressly permitted by the Terms of use uploads or transmits viruses or other harmful,
					disruptive, or destructive files;
				</li>
				<li>
					sends repeated messages related to another user and/or makes derogatory or offensive comments about another individual or repeats prior posting of the same message under
					multiple emails or subjects.
				</li>
				<li>
					Any submitted content that includes, but is not limited to the following, will be refused. If repeated violations occur, we reserve the right to cancel user access to the
					website without advanced notice.
				</li>
			</ul>
			<br />

			<h3>7) INTELLECTUAL PROPERTY</h3>
			<p>
				You agree that the Website and all Services provided by us are the property of Live Rewards, including all copyrights, trademarks, trade secrets, patents, and other
				intellectual property ("Our IP"). You agree that we own all rights, title, and interest in and to the Our IP and that you will not use Our IP for any unlawful or infringing
				purpose. You agree not to reproduce or distribute Our IP in any way, including electronically or via registration of any new trademarks, trade names, service marks, or
				Uniform Resource Locators (URLs), without express written permission from us.
			</p>
			<ul>
				<li>
					To make the Website and Services available to you, you hereby grant us a royalty-free, non-exclusive, worldwide license to copy, display, use, broadcast, transmit and
					make derivative works of any content you publish, upload, or otherwise make available to the Website ("Your Content"). We claim no further proprietary rights in your
					Content.
				</li>
				<li>
					If you feel that any of your intellectual property rights have been infringed or otherwise violated by the posting of information or media by another of our users, please
					contact us and let us know.
				</li>
			</ul>
			<br />

			<h3>9) USER OBLIGATIONS</h3>
			<p>
				As a user of the Website or Services, you may be asked to register with us. When you do so, you will choose a user identifier, which may be your email address or another
				term, as well as a password. You may also provide personal information, including, but not limited to, your name. You are responsible for ensuring the accuracy of this
				information. This identifying information will enable you to use the Website and Services. You must not share such identifying information with any third party, and if you
				discover that your identifying information has been compromised, you agree to notify us immediately in writing. An email notification will suffice. You are responsible for
				maintaining the safety and security of your identifying information as well as keeping us apprised of any changes to your identifying information. Providing false or
				inaccurate information, or using the Website or Services to further fraud or unlawful activity is grounds for immediate termination of this Agreement.
			</p>
			<br />

			<h3>10) ACCEPTABLE USE</h3>
			<p>
				You agree not to use the Website or Services for any unlawful purpose or any purpose prohibited under this clause. You agree not to use the Website or Services in any way
				that could damage the Website, Services, or general business of Live Rewards.
			</p>
			<ul>
				<li>You further agree not to use the Website or Services:</li>
				<li>To harass, abuse, or threaten others or otherwise violate any person's legal rights;</li>
				<li>To violate any of our intellectual property rights or any third party;</li>
				<li>To upload or otherwise disseminate any computer viruses or other software that may damage the property of another;</li>
				<li>To perpetrate any fraud;</li>
				<li>To engage in or create any unlawful gambling, sweepstakes, or pyramid scheme;</li>
				<li>To publish or distribute any obscene or defamatory material;</li>
				<li>To publish or distribute any material that incites violence, hate, or discrimination towards any group;</li>
				<li>To unlawfully gather information about others.</li>
			</ul>
			<br />

			<h3>11) ASSUMPTION OF RISK</h3>
			<p>
				The Website and Services are provided for communication purposes only. You acknowledge and agree that any information posted on Our Website is not intended to be legal
				advice, medical advice, or financial advice, and no fiduciary relationship has been created between you and us. You further agree that your purchase of any of the products
				on the Website is at your own risk. We do not assume responsibility or liability for any advice or other information given on the Website.
			</p>
			<br />

			<h3>12) REVERSE ENGINEERING & SECURITY</h3>
			<p>You agree not to undertake any of the following actions:</p>
			<ul>
				<li>a) Reverse engineer, or attempt to reverse engineer or disassemble any code or software from or on the Website or Services;</li>
				<li>
					b) Violate the security of the Website or Services through any unauthorized access, circumvention of encryption or other security tools, data mining, or interference to
					any host, user, or network.
				</li>
			</ul>
			<br />

			<h3>13) INDEMNIFICATION</h3>
			<p>
				You agree to defend and indemnify us and any of our affiliates (if applicable) and hold us harmless against any legal claims and demands, including reasonable attorney's
				fees, which may arise from or relate to your use or misuse of the Website or Services, your breach of this Agreement, or your conduct or actions. You agree that we shall be
				able to select its legal counsel and may participate in its defense if we wish.
			</p>

			<br />
			<h3>14) EXCLUSION OF LIABILITY</h3>
			<p>
				You understand and agree that we (A) do not guarantee the accuracy, completeness, validity, or timeliness of information listed by us or any third parties; and (B) shall
				not be responsible for any materials posted by us or any third party. You shall use your judgment, caution, and common sense in evaluating any prospective methods or offers
				and any information provided by us or any third party. Further, we shall not be liable for direct, indirect consequential, or any other form of loss or damage that may be
				suffered by a user through the use of the Live Rewards Website including loss of data or information or any kind of financial or physical loss or damage. In no event shall
				Live Rewards, nor its Owner, directors, employees, partners, agents, suppliers, or affiliates, be accountable for any indirect, incidental, special, eventful, or exemplary
				costs, including without limitation, loss of proceeds, figures, usage, goodwill, or other intangible losses, consequential from (i) your use or access of or failure to
				access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content attained from the Service; and (iv) unlawful access, use or
				alteration of your transmissions or content, whether or not based on guarantee, agreement, domestic wrong (including carelessness) or any other lawful concept, whether or
				not we've been aware of the possibility of such damage, and even if a cure set forth herein is originated to have futile of its important purpose.
			</p>

			<br />

			<h3>15) SPAM POLICY</h3>
			<p>
				You are strictly prohibited from using the Website or any of our Services for illegal spam activities, including gathering email addresses and personal information from
				others or sending any mass commercial emails.
			</p>
			<br />

			<h3>16) THIRD-PARTY LINKS & CONTENT</h3>
			<p>
				We may occasionally post links to third-party websites or other services. You agree that we are not responsible for any loss or damage caused as a result of your use of any
				third-party services linked to or from Our Website.
			</p>
			<br />

			<h3>17) MODIFICATION & VARIATION</h3>
			<p>
				We may, from time to time and at any time without notice to you, modify this Agreement. You agree that we have the right to modify this Agreement or revise anything
				contained herein. You further agree that all modifications to this Agreement are in full force and effect immediately upon posting on the Website and that modifications or
				variations will replace any prior version of this Agreement unless prior versions are specifically referred to or incorporated into the latest modification or variation of
				this Agreement.
			</p>
			<br />

			<h3>18) ENTIRE AGREEMENT</h3>
			<p>
				This Agreement constitutes the entire understanding between the Parties concerning any use of this Website. This Agreement supersedes and replaces all prior or
				contemporaneous agreements or understandings, written or oral, regarding the use of this Website.
			</p>
			<br />

			<h3>19) SERVICE INTERRUPTIONS</h3>
			<p>
				We may need to interrupt your access to the Website to perform maintenance or emergency services on a scheduled or unscheduled basis. You agree that your access to the
				Website may be affected by unanticipated or unscheduled downtime, for any reason, but that we shall have no liability for any damage or loss caused as a result of such
				downtime.
			</p>
			<br />

			<h3>20) TERM, TERMINATION & SUSPENSION</h3>
			<p>
				We may terminate this Agreement with you at any time for any reason, with or without cause. We specifically reserve the right to terminate this Agreement if you violate any
				of the terms outlined herein, including, but not limited to, violating the intellectual property rights of us or a third party, failing to comply with applicable laws or
				other legal obligations, and/or publishing or distributing illegal material. If you have registered for an account with Us, you may also terminate this Agreement at any
				time by contacting us and requesting termination. At the termination of this Agreement, any provisions that would be expected to survive termination by their nature shall
				remain in full force and effect.
			</p>
			<br />

			<h3>21) NO WARRANTIES</h3>
			<p>
				You agree that your use of the Website and Services is at your sole and exclusive risk and that any Services provided by us are on an "As Is" basis. We hereby expressly
				disclaim any express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of
				merchantability. We make no warranties that the Website or Services will meet your needs or that the Website or Services will be uninterrupted, error-free, or secure. We
				also make no warranties as to the reliability or accuracy of any information on the Website or obtained through the Services. You agree that any damage that may occur to
				you, through your computer system, or as a result of the loss of your data from your use of the Website or Services is your sole responsibility and that we are not liable
				for any such damage or loss.
			</p>
			<br />

			<h3>22) LIMITATION ON LIABILITY</h3>
			<p>
				We are not liable for any damages that may occur to you as a result of your use of the Website or Services, to the fullest extent permitted by law. This section applies to
				any claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.
			</p>
			<br />

			<h3>23) GENERAL PROVISIONS:</h3>
			<ul>
				<li>
					<strong>JURISDICTION, VENUE & CHOICE OF LAW</strong>: The terms herein will be governed by and construed by the laws of (California)United States without giving effect to
					any principles of conflicts of law. The Courts of (California)United States shall have exclusive jurisdiction over any dispute arising from the use of the Website.
				</li>
				<li>
					<strong>ASSIGNMENT</strong>: This Agreement, or the rights granted hereunder, may not be assigned, sold, leased, or otherwise transferred in whole or part by you. Should
					this Agreement, or the rights granted hereunder, be assigned, sold, leased, or otherwise transferred by us, the rights and liabilities of Live Rewards will bind and inure
					to any assignees, administrators, successors, and executors.
				</li>
				<li>
					<strong>SEVERABILITY</strong>: If any part or sub-part of this Agreement is held invalid or unenforceable by a court of law or competent arbitrator, the remaining parts
					and sub-parts will be enforced to the maximum extent possible. In such a condition, the remainder of this Agreement shall continue in full force.
				</li>
				<li>
					<strong>NO WAIVER</strong>: If we fail to enforce any provision of this Agreement, this shall not constitute a waiver of any future enforcement of that provision or any
					other provision. Waiver of any part or sub-part of this Agreement will not constitute a waiver of any other part or sub-part.
				</li>
				<li>
					<strong>HEADINGS FOR CONVENIENCE ONLY</strong>: Headings of parts and sub-parts under this Agreement are for convenience and organization, only. Headings shall not affect
					the meaning of any provisions of this Agreement.
				</li>
				<li>
					<strong>NO AGENCY, PARTNERSHIP, OR JOINT VENTURE</strong>: No agency, partnership, or joint venture has been created between the Parties as a result of this Agreement. No
					Party has any authority to bind the other to third parties.
				</li>
				<li>
					<strong>FORCE MAJEURE</strong>: We are not liable for any failure to perform due to causes beyond its reasonable control including, but not limited to, acts of God, acts
					of civil authorities, acts of military authorities, riots, embargoes, acts of nature, and natural disasters, and other acts which may be due to unforeseen circumstances,
					i.e., COVID-19!
				</li>
				<li>
					<strong>ELECTRONIC COMMUNICATIONS PERMITTED</strong>: Electronic communications are permitted to both Parties under this Agreement, including e-mail. For any questions or
					concerns, please use the contact us form on the website or email us info@liverewardsgo.com.
				</li>
			</ul>

			<br />

			<strong>Live Rewards </strong>
			<p>
				<strong>E-mail</strong>: info@liverewardsgo.com
			</p>
			<p>
				<strong>Phone</strong>: 877-744-7606
			</p>
			<p>
				<strong>Address</strong>: 300 Pacific Coast Hwy Suite 114, Huntington Beach, CA 92648
			</p>

			<br />
			<p>document was last updated on September 4, 2021</p>

			<Spacer size={150} />
		</TermSceenBody>
	);
};

const TermSceenBody = styled.div`
	padding: 20px;
	@media only screen and (min-width: 900px) {
		padding: unset;
		max-width: 900px;
		margin: 0 auto;
	}

	ul {
		/* list-style-type: none; */
		margin-bottom: 50px;
		margin-top: 10px;
		li {
			margin-left: 50px;
			margin-bottom: 10px;
		}
	}
`;
