import * as React from 'react';
import styled from 'styled-components';
import { Spacer } from '../../parts';

interface PrivacyScreenProps {}

export const PrivacyScreen: React.FC<PrivacyScreenProps> = (props) => {
	const {} = props;

	return (
		<PrivacyScreenBody>
			<h2>Privacy Policy</h2>
			<p>
				This online privacy policy has been put together to better those who find themselves worried about how their Personally Identifiable Information (PII) hasbeen used online.
				As defined in US privacy regulation and information security, PII is information that can be used alone or with other information to recognize, contact, or find a single
				person, or even to identify a person in the framework. Please readour online privacy policy carefully to obtain an explicit knowledge of how we accumulate, use, protect or
				else where take care of your Personally Identifiable Information relative to our website.
			</p>
			<ul>
				<li>
					Your privacy is essential to us, and we are firmly dedicated to doing ourworks concerning your information increasingly straightforward and more attractive. It would be
					ideal if you read this Privacy Policy cautiously and make sure that you completely comprehend and consent to it.
				</li>
				<li>You are not legally obligated to provide us with any Personal Data (asdescribed below), and you can do so (or refrain from doing so) at yourdiscretion.</li>
				<li>
					If you do not want to assist us with such Personal Data or have it compiled byus or any of our Service Providers (described below), simply do not accessour Sites or use
					our Services.
				</li>
				<li>
					You may likewise decide not to give us "discretionary" Personal Data;however please remember that without it, we will most likely be unable toprovide you with the full
					scope of our administrations or with the best client experience when utilizing our Services.
				</li>
				<li>Live Rewards provides an online platform for your convenience.</li>
			</ul>
			<h3>What private data do we collect from the people who visit ourwebsite/App?</h3>
			<p>
				When enlisting on our site or using services, as suitable, you could be approached to type in your name, email, postage information, payment information, or different
				subtleties to assist you with your experience.
			</p>
			<br />
			<p>When you create an account and use the Services, including through a third-party platform, we collect any data you provide directly, including:</p>
			<ul>
				<li>
					<strong>Account Data:</strong>To use certain features (like Paid or unpaid Services), youneed to create a user account. When you create or update your account, we collect
					and store the data you provide, like your email address, password,and assign you a unique identifying number ("Account Data").
				</li>
				<li>
					<strong>Personal Data:</strong>Personal Data is information that can be used to identify you specifically, including your name, zip code, time zone, email address,
					telephone number, or demographic information like your age, gender, or hometown. You consent to give us this information by providing it to us voluntarily on our website
					or any mobile application. You provide some of this information when you register with or make purchases from our website/App.You may also provide this information by
					participating in various activities associated with our site, including responding to blogs, contacting us withquestions, or participating in group training. Your
					decision to disclose thisdata is entirely voluntary. You are under no obligation to provide this information, but your refusal may prevent you from accessing certain
					benefits from our website/App or from making purchases.
				</li>
				<li>
					<strong>Financial Data:</strong>Financial data is related to your payment methods, such ascredit card or bank transfer details. We collect financial data to allow you
					topurchase, order, return or exchange services from our website and anyrelated mobile apps. We store limited financial data. Most financial data istransferred to our
					payment processor, Stripe, and you should review theseprocessors' Privacy Policy to determine how they use, disclose and protectyour financial data.
				</li>
			</ul>
			<p>- Stripe Privacy Policy can be found here https://stripe.com/gb/privacy</p>

			<br />

			<h3>When do we acquire information?</h3>
			<p>We get data from you when you get enlisted on our site, respond to an audit, giveus reactions on our items or enter information on our site. Below are the examples:</p>
			<ul>
				<li>To register on our website/App to get updated about services.</li>
				<li>To create your account on the website/App (e.g., your name and email address)</li>
				<li>
					To deal with our relationship with you, including notifying you aboutchanges to our terms or security arrangement. Requesting that youleave an auditor to take an
					overview.
				</li>
				<li>
					To manage and ensure our business and this site (counting investigating, information examination, testing, framework upkeep,backing, announcing, and facilitating
					information).
				</li>
				<li>To send you our email pamphlet and other robotized emailcorrespondences.</li>
				<li>To make proposals and suggestions to you about merchandise oradministrations that might hold any importance with you.</li>
			</ul>
			<h3>Automatically collected information about your use of our Services or tools,</h3>
			<p>This information is registered automatically with the visit by own configuration ormanual of each tool on the website/App</p>
			<ul>
				<li>
					When you visit, connect with, or utilize our service, we may gather, record orcreate specific specialized data about you. We do so either autonomously orwith the
					assistance of third gathering Service Providers, including using <strong>"cookies"</strong> and other following innovations.
				</li>
				<li>
					Such data comprises of availability, specialized and collected utilization data,for example, <strong>IP locations</strong> and <strong>general areas, gadget data</strong>
					(like sort,working framework, cell phone id, program form, region and languagesettings, Browser Information, Geographic location, Time of Visit, Referringsite,
					applications or service, search engine utilized), date and time stamps ofuse, the and pixels introduced or used on such gadget and the recorded
				</li>
				<li>
					movement (meetings, clicks and further cooperation's) of Visitors and Usersregarding our Service. for purposes including examination, service-, tasks,and business quality
					control and enhancements, and record-keepingpurposes.
				</li>
			</ul>
			<p>This is to improve the website/App, services, and security, among which we includesecurity inspection by the administration of the website/App and third parties.</p>
			<h3>How do we use your details?</h3>
			<p>
				We may utilize the data we procure from you when you enlist, make a buy, join ourbulletin, respond to an examination or promoting correspondence, peruse the site,or utilize
				specific other site includes in the following ways:
			</p>
			<ul>
				<li>Create your account; and</li>
				<li>Correspond with you; and</li>
				<li>Compile anonymous statistical data for our own use or for a third party's use;and</li>
				<li>Assist law enforcement as necessary; and</li>
				<li>Prevent fraudulent activity on our website or mobile app; and</li>
				<li>Analyze trends to improve our website/App and offerings.</li>
				<li>To fulfill or meet the reason you provided the information (e.g., to helpprovide our Site services to you).</li>
				<li>To personalize and develop our site and the services we provide you andimprove our offerings.</li>
				<li>To provide certain features or functionality of the services on the site.</li>
				<li>For marketing and promotions.</li>
				<li>To create, maintain, customize, and secure your account with us.</li>
				<li>
					To provide you with support, to communicate with you and respond to yourinquiries, including to investigate and address your concerns and monitor andimprove our
					responses.
				</li>
				<li>To personalize your experience and to deliver content and services relevantto your interests.</li>
				<li>To help maintain the safety, security, and integrity of our site, services,databases, and other technology assets and business.</li>
				<li>For testing, research, analysis, and website or app development, including todevelop and improve our site and services.</li>
				<li>To respond to law enforcement requests and as required by applicable law,court order, or governmental regulations.</li>
				<li>To prevent illegal activity, fraud, and abuse.</li>
				<li>As described to you when collecting your personal information or asotherwise set forth in the CCPA.</li>
				<li>Where we have to play out the agreement we are going to go into or havegone into with you</li>
				<li>To help our site that will ready to serve you better.</li>
				<li>To allow us to brought administration you up in furnishing a response to yourclient assistance demands.</li>
				<li>To procure rankings and audits of items</li>
				<li>To send messages after a certain time routinely, with respect to youradministrations or items and different items.</li>
				<li>To catch up after correspondence with (live talk, email, or telephonerequests)</li>
				<li>To furnish you with the data, items, and administrations that you demandfrom the site.</li>
			</ul>

			<br />

			<h3>How do we protect your details?</h3>
			<ul>
				<li>We do not use vulnerability scanning and/or scanning to PCI specifications.</li>
				<li>We use regular Malware Scanning.</li>
				<li>
					Your individual information is comprised behind secured systems and ismerely accessible by a restricted number of folks who've special accessprivileges to such systems
					and must keep the information confidentialcarefully. Furthermore, all very sensitive/credit information you resource isencrypted via Secure Socket Layer (SSL) technology.
				</li>
				<li>
					We implement a number of security measures whenever a user gets into,submits, or accesses their information to keep up the protection of yourindividual information.
				</li>
			</ul>

			<br />
			<h3>Do we use 'cookies?</h3>
			<p>
				Yes. Cookies are small documents a site or its provider exchanges to yourcomputer's hard drive through your browser (if you allow) that permit the site's orservice
				provider's systems to identify your internet browser and capture pleaseremember certain information. For example, we use cookies to help us keep in mindand process the
				things in your shopping cart software. Also, they are used to helpus understand your requirements based on prior or current site activity, whichpermits us to offer you
				improved upon services. We also use cookies to help us puttogether aggregate data about site traffic and site conversation so that people mayoffer better site experience
				and tools in the foreseeable future.
			</p>

			<br />

			<h3>We use cookies to:</h3>
			<ul>
				<li>Understand and save user's tastes for future views or visits of our site.</li>
				<li>Keep an eye on advertisements.</li>
				<li>Compile aggregate data about site traffic and site connections in order toprovide better site activities and tools in the foreseeable future.</li>
				<li>
					We also use third-party advertisements on Live Rewards to support our site.Some of these advertisers may use technology such as cookies and webbeacons when they advertise
					on our site, which will also send theseadvertisers (such as Google through the Google AdSense program)information including your IP address, your ISP, the browser you
					used to visitour site, and in some cases, whether you have Flash installed. This isgenerally used for geotargeting purposes (Serving Online Booking ads toTravelers) or
					showing certain ads based on specific sites visited (such asshowing marketing ads to someone who frequents marketing sites or blogs).
				</li>
				<li>We might also use third-party services that monitor these details on ourbehalf.</li>
			</ul>

			<br />

			<p>
				You are able to choose that your personal computer warns you whenever a cookiehas been directed, or you can select to turn off all cookies carefully. You canperform that
				through your web browser settings. Since the internet browser is just a little different, check out your browser's Help Menu to learn the way in which tochange your
				cookies.
			</p>

			<br />

			<p>
				If you change cookies off, many of the features that produce your site experiencebetter might not exactly function properly. It will not impact the user's experience,which
				builds your site experience better and might not function properly.
			</p>

			<br />

			<h3>Types of Cookies we use Mandatory Cookies</h3>
			<p>
				required cookie empowers you to explore the website/App and utilize its features,for example, getting to get secure areas of the site and using services at ourwebsite/App.
				In the event that you have decided to recognize yourself to thewebsite/App, the company may put on your program treats containing an encoded,one-of-a-kind identifier. These
				cookies permit the company to extraordinarilyrecognize you when you are signed into the Web site and Services and to deal withyour online exchanges and demands.
			</p>

			<br />
			<h3>Focus or Targeting cookies</h3>

			<p>
				Site may in some cases use cookie conveyed by outsiders to show you promotionsfor Website/App items and administrations that we think may intrigue you on anygadgets you may
				utilize and to follow the exhibition of Company notices. Forinstance, in these cases, cookie recall data, for example, which programs havevisited the Company's Web locales.
				The data gave to outsiders does excludeindividual data, yet this data might be re-related with individual data after thecompany gets it. In the event that the website/App
				is utilizing one of its own treatrelated items on our own website/App, at that point a cookie identified withpromotions may show up on our Web webpage under the website/App
				or one of ourassociated organization's names.
			</p>

			<br />
			<h3>Performance Cookies</h3>

			<p>
				Performance Cookies permit the Company's Web destinations and Services torecollect data you have entered or decisions you make, (for example, yourusername, language, or
				your area) and give upgraded, closer-to-home highlights.These Cookies additionally empower you to improve your utilization of LiveRewards's Web site and Services subsequent
				to signing in. These Cookies canlikewise be utilized to recall transforms you have made to message size, text stylesand different pieces of website/App pages that you can
				change.
			</p>

			<br />

			<p>
				Performance Cookies may likewise be utilized to improve how the Company'sWebsite/App and Services work and perform, to upgrade and redo your associationswith the company,
				and to assist us with furnishing you with more pertinentmessages, including promoting interchanges. These Cookies gather data about howVisitors utilize our Web destinations
				and Services, including which pages guests goto frequently and in the event that they get blunder messages from specific pages.Live Rewards may utilize its own innovation
				(under the Live Rewards brand name ora partnered brand name) or outsiders to follow and examine use and volume factualdata from Visitors, Attendees, and Customers, to give
				upgraded associations andmore pertinent correspondences, and to follow the presentation of the company's
			</p>

			<br />

			<p>
				commercials. Live Rewards and its outsider accomplices may likewise use HTML5nearby capacity or Flash Cookies for these reasons. Streak Cookies and HTML neighborhood
				stockpiling is unique in relation to program Cookies due to themeasure of, sort of, and how information is put away. Live Rewards likewise utilizes Flash Cookies, to store
				your inclinations or show content depending on what yousee on our Website/App and Services to customize your visit.
			</p>

			<br />
			<table>
				<tr>
					<th>FacebookPixel</th>
					<th>
						Collects anonymous statistics regarding usage of theLiverewardsgo.com website. These are third-party cookies.While Liverewardsgo.com 's use of Facebook causes
						thesecookies to be used, Liverewardsgo.com itself does not controlthe data within the cookies themselves. The names of thecookies listed are provided as examples.
						Liverewardsgo.comdoes not directly control the names of the cookies involved, andthe actual names may differ.
					</th>
				</tr>

				<tr>
					<td>_fpb,datr,dpr,fr,wd</td>
					<td>
						<p>These cookies enable us to:</p>
						<ul>
							<li>1.Determine the effectiveness of certain marketingcampaigns</li>
							<li>2.Collect additional anonymous statistics (see below)</li>
						</ul>
						<p>You can learn more about Facebook's Tracking Pixel here</p>
					</td>
				</tr>
			</table>

			<br />

			<h3>General Use</h3>
			<ul>
				<li>Understand client's desires for future perspectives or visits of our site.</li>
				<li>
					Collect and Compile total data about site traffic their sources e.g.(Gadgets, Country, page sessions) and site associations so as to givebetter site exercises and
					apparatuses within a reasonable time-frame.{' '}
				</li>
				<li>
					You have the choice to accept or reject cookies for the website and atwhatever point a cookie has been coordinated, or you can choose todeliberately kill all cookies. You
					can play out that through your internetbrowser settings if you using chrome, you can go the setting of thechrome and select the domain and change the setting of the
					cookies toyour desire.{' '}
				</li>
				<li>
					On the off chance that you change cookies off, some of the featuresthat produce your site experience better may not actually workappropriately. It won't affect the
					client's experience that delivers yoursite experience better and may not actually work appropriately.
				</li>
			</ul>

			<br />
			<h3>California online privacy protection act</h3>
			<p>
				CalOPPA is the first state law in the country to require commercial websites and online services to create an online privacy policy. The law's reach extends well beyond
				California to require anybody or company inAmerica (and possibly the entire world) that functions websites collecting (PII)Personally Identifiable Information from
				California consumers to create a visibleonline privacy policy on its website declaring the information being accumulatedand the individuals or companies with whom it has
				been distributed. –See more athttps://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
			</p>

			<br />

			<h3>According to caloppa, we agree to the following:</h3>
			<ul>
				<li>Users can visit our site anonymously.</li>
				<li>Once this online privacy policy is established, we will put a link to it on ourwebsite, on the first significant web page after getting into our website.</li>
				<li>Our ONLINE PRIVACY POLICY link includes the term 'Privacy' and can certainlybe on the page given above.</li>
				<li>You'll be notified of any ONLINE PRIVACY POLICY changes: Via Email</li>
			</ul>

			<br />

			<h3>Coppa (children online privacy protection action)</h3>
			<p>
				With regards to the assortment of private information from children under age 13years, the Children's Online Privacy Protection Act (COPPA) puts parents in charge.The
				Federal Trade Commission, United States' consumer safety firm, enforces theCOPPA Guideline, which spells out what providers of websites and online servicesshould do to
				safeguard children privatizes and security online. Fore more DetailsClick Here or below link
			</p>
			<br />
			<p>https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule</p>
			<br />

			<h3>Fair information practices</h3>
			<p>
				The fair Information Practices Rules form the backbone of level of privacy law andthe ideas they include have played an important role in the introduction of dataprotection
				laws and regulations around the world. Understanding the GoodInformation Practice Guidelines and how they must be implemented is fundamentalto adhere to the various
				privation laws and regulations that protect privateinformation.
			</p>

			<br />

			<h3>To become consistent with Fair Information Methods we will need thefollowing responsive action, should a data breach happen:</h3>
			<ul>
				<li>We will inform you via email</li>
				<li>within 7 business days</li>
				<li>We will inform the users via in-site notification</li>
				<li>Within 1 working day</li>
			</ul>
			<p>
				We also agree to the average person Redress Rule which requires that peoples havethe right to legally go after enforceable privileges against data collectors andprocessors
				who neglect to adhere to the law. This theory requires not just thatpeople have enforceable protection under the law against data users, but also thatperson have recourse
				to courts or federal government agencies to research and/orprosecute non-compliance by data processors
			</p>

			<br />

			<h3>Your Legal Rights</h3>
			<p>Under certain circumstances, you have rights under data protection laws in relationto your personal data.</p>
			<br />
			<h3>You may have the following rights:</h3>
			<ul>
				<li>
					<strong>Request access</strong> to your personal data (commonly known as a "data subjectaccess request"). This enables you to receive a copy of the personal data wehold
					about you and to check that we are lawfully processing it.
				</li>
				<li>
					<strong>Request correction</strong>of the personal data that we hold about you. Thisenables you to have any incomplete or inaccurate data we hold about youcorrected,
					though we may need to verify the accuracy of the new data youprovide to us.
				</li>
				<li>
					<strong>Request erasure</strong>
					<p>
						of your personal data. This enables you to ask us to deleteor remove personal data where there is no good reason for us continuing toprocess it. You also have the right
						to ask us to delete or remove yourpersonal data where you have successfully exercised your right to object toprocessing (see below), where we may have processed your
						informationunlawfully or where we are required to erase your personal data to complywith local law. Note, however, that we may not always be able to comply withyour
						request of erasure for specific legal reasons which will be notified to you,if applicable, at the time of your request.
					</p>
				</li>
				<li>
					<p>
						<strong>Object to processing</strong> of your personal data where we are relying on alegitimate interest (or those of a third party) and there is something aboutyour
						particular situation which makes you want to object to processing onthis ground as you feel it impacts on your fundamental rights and freedoms.You also have the right
						to object where we are processing your personal datafor direct marketing purposes. In some cases, we may demonstrate that wehave compelling legitimate grounds to
						process your information which override your rights and freedoms.
					</p>
				</li>
				<li>
					<strong>Request restriction</strong> of processing of your personal data. This enables youto ask us to suspend the processing of your personal data in the following
					scenarios:
					<ul>
						<li>If you want us to establish the data's accuracy</li>
						<li>Where our use of the data is unlawful, but you do not want us to eraseit.</li>
						<li>Where you need us to hold the data even if we no longer require it asyou need it to establish, exercise or defend legal claims.</li>
						<li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
					</ul>
				</li>
				<li>
					<strong>Request the transfer</strong>of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a
					structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where
					we used the information to perform a contract with you.
				</li>
				<li>
					<strong>Withdraw consent</strong> at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any
					processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you.
				</li>
			</ul>
			<br />

			<h3>Can-spam act</h3>
			<p>
				The CAN-SPAM Take action is a regulation that sets the guidelines for commercial email, establishes requirements for commercial announcements, offers recipients to have
				emails ceased from being delivered to them, and spells out hard fines for violations. We accumulate your email to be able to:
			</p>
			<ul>
				<li>Send information, react to questions, and/or other demands or questions</li>
				<li>To maintain compliance with CANSPAM, we consent to the next:</li>
				<li>Not use untrue or misleading subject matter or email addresses.</li>
				<li>Identify the concept as an advertisement in some realistic way.</li>
				<li>Include the physical address of our site headquarters or business</li>
				<li>Screen third-party email marketing services for conformity, if one can be used.</li>
				<li>Honor opt-out/unsubscribe demands quickly.</li>
				<li>Allow users to unsubscribe utilizing the link at the bottom of every email.</li>
			</ul>
			<p>
				If anytime you want to unsubscribe from receiving future email, you can email us at by using contact form at our website Live Rewards and we'll immediately remove you from
				ALL communication.
			</p>

			<br />

			<h3>Limitation of liability</h3>
			<ul>
				<li>
					Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you
				</li>
				<li>
					We make no legal representation that the website or services are appropriate or available for use in locations outside (California)United States. You may access the
					website from outside (California)United States.at your own risk and initiative and must bear all responsibility for compliance with any applicable foreign laws.
				</li>
			</ul>
			<h3>Indemnification</h3>
			<ul>
				<li>
					Upon visiting this website you agree release, indemnify, defend and hold harmless Live Rewards and any of its contractors, agents, employees, officers, directors,
					shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties
					relating to or arising out of your use of the website content; your use of the services; your violation of any provision of these terms; any unauthorized information or
					data you supplied to us. You will have sole responsibility to defend us against any claim, but you must receive our prior written consent regarding any related
					settlement.
				</li>
			</ul>

			<br />

			<h3>Governing Law and Jurisdiction</h3>
			<ul>
				<li>
					This website originates from (California)United States. The laws of (California)United States. without regard to its conflict of law principles will govern these terms to
					the contrary. You, hereby agree that, all disputes arising out of or in connection with these terms shall be submitted to the exclusive jurisdiction of the
					(California)United States. By using this website, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim
					arising under or by reason of these terms. You hereby waive any right to trial by jury arising out of these terms.
				</li>
			</ul>

			<br />
			<h3>Changes to this privacy notice</h3>
			<p>
				We reserve the right to alter this privacy notice at any time. Such alterations will be posted on our website. You can also obtain an up-to-date copy of our privacy notice
				by contacting us.
			</p>

			<br />

			<h3>Contacting us</h3>
			<p>
				If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal
				Information, you may do so via the contact us or email us.{' '}
			</p>

			<br />

			<h3>Live Rewards</h3>
			<p>
				<strong>E-mail: </strong>info@liverewardsgo.com
			</p>
			<p>
				<strong>Phone: </strong>877-744-7606
			</p>
			<p>
				<strong>Address: </strong>300 Pacific Coast Hwy Suite 114, Huntington Beach, CA 92648
			</p>
			<br />
			<br />
			<p>document was last updated on September 4, 2021</p>
			<Spacer size={130} />
		</PrivacyScreenBody>
	);
};

const PrivacyScreenBody = styled.div`
	padding: 20px;
	@media only screen and (min-width: 900px) {
		padding: unset;
		max-width: 900px;
		margin: 0 auto;
	}

	ul {
		/* list-style-type: none; */
		margin-bottom: 50px;
		margin-top: 10px;
		li {
			margin-left: 50px;
			margin-bottom: 10px;
		}
	}

	table {
		font-family: arial, sans-serif;
		border-collapse: collapse;
		width: 100%;
	}

	td,
	th {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}

	tr:nth-child(even) {
		background-color: #dddddd;
	}
`;

const Container = styled.div`
	/* margin: 0px 40px; */
`;
