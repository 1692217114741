import * as React from 'react';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { Center, Spacer, Text } from '../../parts';
import { request } from '../../utils';

// import ReactPixel from 'react-facebook-pixel';

// if (process.env.NODE_ENV === 'production') {
// 	ReactPixel.init('195152595908636');
// 	ReactPixel.pageView();
// }

export const CancelScreen: React.FC = () => {
	const alert = useAlert();
	const [Email, setEmail] = useState('');

	const submitCancallation = async (): Promise<any> => {
		const a = await request('post', 'subscription/cancel', { email: Email });
		if (!a?.success) alert.error(a?.message);
		else alert.success(a?.message);
	};

	return (
		<CancelScreenBody>
			<Center>
				<Spacer size={50} />
				<Spacer size={50} />
				<Spacer size={50} />
				<Spacer size={50} />
				<Text weight="bold" size={30}>
					Cancel Live Rewards Membership
				</Text>
				<Spacer size={20} />
				<Input placeholder="Enter your email" onChange={(v) => setEmail(v.target.value)} />
				<Spacer size={10} />
				<Button onClick={() => submitCancallation()} disabled={Email === ''} >Submit</Button>
				<Spacer size={10} />
				<Text color="gray">You will receive an email to confirm your cancellation of your subscription</Text>
			</Center>
		</CancelScreenBody>
	);
};

const CancelScreenBody = styled.div`
	height: 100%;
`;

const Input = styled.input`
	padding: 14px;
	font-weight: 400;
	font-size: 14px;
	font-family: 'Source Sans Pro', sans-serif;
	border-radius: 10px;
	border: solid 1px #eaeaea;
	background: #fbfbfb;
	height: 25px;
	width: 300px;
	outline: none;
	margin-bottom: 8px;

	transition: border-color 0.2s ease-in-out;
	&:focus {
		border-color: #1571ed;
	}

	&::placeholder {
		color: #9e9e9e;
		font-weight: 500;
	}
`;

const Button = styled.button`
	background-color: #1372ed;
	color: white;
	font-weight: 700;
	font-size: 18px;
	padding: 10px;
	width: 300px;
	border: none;
	border-radius: 10px;
	cursor: pointer;
	height: 50px;

	transition: opacity 0.1s ease-in-out;
	&:hover {
		opacity: 0.6;
	}
	&:active {
		opacity: 0.4;
	}
	&:disabled {
		opacity: 0.5;
		cursor: normal;
	}
`;
