import * as React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { request } from '../../utils';
import { useEffect, useState } from 'react';
import { BoxWrapper, Row, Spacer, Text } from '../../parts';
import wave from '../../assets/wave.svg';
import phones from '../../assets/phoness.png';
import { useAlert } from 'react-alert';

export const CustompageView: React.FC = () => {
	const alert = useAlert();
	const params = useParams<{ slug: string }>();
	const nav = useNavigate();

	const [Email, setEmail] = useState('');
	const [FirstName, setFirstName] = useState('');
	const [LastName, setLastName] = useState('');
	const [ErrorMessage, setErrorMessage] = useState('');

	const [bussInfo, SetBussInfo] = useState<{ id: string; attachment_id: string; name: string }>();
	const [loaded, SetLoaded] = useState(false);

	const getCompanyInfo = React.useCallback(async (): Promise<any> => {
		try {
			const a = await request('get', 'business/public/' + params.slug, {}, { path: 'https://portal_api.liverewardsgo.com/' });
			SetBussInfo(a.data);
			SetLoaded(true);
		} catch (e) {
			nav('/');
		}
	}, [params.slug]);

	const startTrial = React.useCallback(async () => {
		if (!Email && !FirstName && !LastName) {
			alert.success('All fields are required');
			setErrorMessage('All fields are required');
			return;
		}

		const a = await request(
			'post',
			`business/public/${params.slug}/member/register`,
			{
				email: Email?.trimStart()?.trimRight(),
				first_name: FirstName,
				last_name: LastName,
			},
			{ path: 'https://portal_api.liverewardsgo.com/' }
		);
		if (a.success) alert.success('Check your email');
	}, [params.slug, Email, FirstName, LastName]);

	// @ts-ignore
	useEffect(() => getCompanyInfo(), []);

	return (
		<>
			<WaveImg src={wave} alt={'wave'} />

			<MainScreenBody>
				<Spacer size={40} />

				<Box>
					<LeftSide>
						<Text size={45} weight="heavy" color={'#037DFC'}>
							Never Pay Full Price Again
						</Text>
						<Spacer size={40} />

						<Text size={30} weight="bold">
							Unlimited digital access to offers, discounts, savings and more.
						</Text>
						<Spacer size={10} />
						<Text>Your exclusive discount membership program, brought to you for FREE by {bussInfo?.name}</Text>

						<Spacer size={30} />

						<Input value={Email} placeholder="Email" onChange={(v) => setEmail(v.target.value)} />
						<Input value={FirstName} placeholder="First name" onChange={(v) => setFirstName(v.target.value)} />
						<Input value={LastName} placeholder="Last name" onChange={(v) => setLastName(v.target.value)} />
						<br />
						<Button onClick={async () => startTrial()} disabled={!Email || !FirstName || !LastName}>
							Get started
						</Button>
						<Spacer size={10} />
						<Text size={14}>
							You agree with the <a href="/terms">terms of service</a> and <a href="/privacy">privacy & policy</a>
						</Text>
					</LeftSide>
					<ImageArea>
						<Spacer size={20} />
						{bussInfo && (
							<BoxWrapper>
								<Logo src={`https://cdn.liverewardsgo.com/business/${bussInfo.id}/files/${bussInfo.attachment_id}`} alt={'logo'} />
								<Spacer size={20} />
							</BoxWrapper>
						)}
						<Img src={phones} alt="phone2" height={750} />
					</ImageArea>
				</Box>
				<Spacer size={250} />
			</MainScreenBody>
		</>
	);
};

const MainScreenBody = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	@media only screen and (min-width: 1200px) {
		padding: 0px;
		max-width: 1160px;
		margin: 0 auto;
	}
`;

const WaveImg = styled.img`
	width: 100%;
	position: relative;
	z-index: 5;

	@media only screen and (max-width: 2600px) {
		margin-top: -760px;
	}

	@media only screen and (max-width: 2500px) {
		margin-top: -730px;
	}

	@media only screen and (max-width: 2400px) {
		margin-top: -700px;
	}

	@media only screen and (max-width: 2300px) {
		margin-top: -670px;
	}

	@media only screen and (max-width: 2200px) {
		margin-top: -640px;
	}

	@media only screen and (max-width: 2100px) {
		margin-top: -610px;
	}

	@media only screen and (max-width: 2000px) {
		margin-top: -580px;
	}

	@media only screen and (max-width: 1900px) {
		margin-top: -550px;
	}

	@media only screen and (max-width: 1800px) {
		margin-top: -520px;
	}

	@media only screen and (max-width: 1700px) {
		margin-top: -490px;
	}

	@media only screen and (max-width: 1600px) {
		margin-top: -460px;
	}

	@media only screen and (max-width: 1500px) {
		margin-top: -430px;
	}

	@media only screen and (max-width: 1400px) {
		margin-top: -400px;
	}

	@media only screen and (max-width: 1300px) {
		margin-top: -350px;
	}

	@media only screen and (max-width: 1200px) {
		margin-top: -330px;
	}

	@media only screen and (max-width: 1100px) {
		margin-top: -300px;
	}

	@media only screen and (max-width: 1000px) {
		margin-top: -270px;
	}

	@media only screen and (max-width: 900px) {
		margin-top: -250px;
	}

	@media only screen and (max-width: 800px) {
		margin-top: -220px;
	}

	@media only screen and (max-width: 700px) {
		margin-top: -180px;
	}

	@media only screen and (max-width: 600px) {
		margin-top: -160px;
	}

	@media only screen and (max-width: 503px) {
		margin-top: -140px;
	}

	@media only screen and (max-width: 450px) {
		margin-top: -120px;
	}
`;

const LeftSide = styled.div`
	display: flex;
	flex-direction: column;
	width: 700px;

	button {
		@media only screen and (max-width: 1100px) {
			width: 100%;
		}
	}

	@media only screen and (max-width: 650px) {
		max-width: 300px;
	}
`;

const Box = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media only screen and (max-width: 1100px) {
		flex-wrap: wrap;
		justify-content: center;
	}
`;

const ImageArea = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Img = styled.img`
	@media only screen and (max-width: 500px) {
		max-width: 290px;
		height: auto;
	}
`;

const Button = styled.button`
	background-color: #1372ed;
	color: white;
	font-weight: 700;
	font-size: 18px;
	padding: 10px;
	width: calc(100% + 20px);
	border: none;
	border-radius: 10px;
	cursor: pointer;
	height: 50px;

	transition: opacity 0.1s ease-in-out;

	&:hover {
		opacity: 0.6;
	}

	&:active {
		opacity: 0.4;
	}

	&:disabled {
		opacity: 0.5;
		cursor: auto;
	}
`;

const Input = styled.input`
	padding: 14px;
	font-weight: 400;
	font-size: 14px;
	font-family: bozon;
	border-radius: 10px;
	border: solid 2px #f1f1f1;
	background: #fbfbfb;
	height: 25px;
	width: calc(100% - 14px);
	outline: none;
	margin-bottom: 8px;

	transition: border-color 0.2s ease-in-out;

	&:focus {
		border-color: #1571ed;
	}

	&::placeholder {
		font-family: 'bozon', sans-serif;
		color: #ababab;
		font-weight: 600;
	}
`;

const Logo = styled.img`
	max-height: 180px;
	width: auto;
	max-width: 310px;

	@media only screen and (max-width: 450px) {
		margin-top: 50px;
	}
`;
