import styled from 'styled-components';
import { BoxWrapper, Fill, IconButton, Row, Text, Spacer } from '../parts';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import logoColored from '../assets/liverewards_logo.png';
import logo from '../assets/logo_white.svg';
import wave from '../assets/wave.svg';
import { core } from 'core';

const ICON_SIZE = 20;
const ICON_COLOR = '#454444';

export const Header: React.FC = () => {
	let location = useLocation();
	const [ShowWhiteLogo, SetShowWhiteLogo] = useState(false);

	useEffect(() => {
		SetShowWhiteLogo(!['/contact', '/business', '/cancel', '/terms', '/privacy', '/press', '/about'].includes(location.pathname));
		console.log(location);
	}, [location]);

	const go = (v: string) => {
		window.location.assign(v);
	};

	const openModal = () => {
		core._events['buss-modal'].send();
	};

	return (
		<HeaderB>
			<Cont row>
				<Row style={{ alignItems: 'flex-start', marginLeft: 5 }}>
					<img
						src={ShowWhiteLogo ? logo : logoColored}
						alt="logo"
						height="69"
						width="95"
						onClick={() => window.location.assign('https://home.liverewardsgo.com')}
						style={{ cursor: 'pointer', objectFit: 'contain' }}
					/>
					{/* <Text color="black" style={{ marginTop: 5 }} size={13}>
						™
					</Text> */}
				</Row>

				<Fill />
				<TextBtn>Home</TextBtn>
				<TextBtn onClick={() => go('https://blog.liverewardsgo.com')}>Blog</TextBtn>
				{location.pathname === '/business' && <GetaccesBtn onClick={() => openModal()}>ENROLL NOW</GetaccesBtn>}
				<Icons>
					<Iconbtn name="instagram" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.instagram.com/liverewardstoday/')} />
					<Iconbtn name="tiktok" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.tiktok.com/@liverewards?is_copy_url=1&is_from_webapp=v1')} />
					<Iconbtn name="twitter" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://twitter.com/LiveRewardsgo')} />
					<Iconbtn name="linkedin" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.linkedin.com/company/live-rewards/')} />
					<Iconbtn name="facebook" size={ICON_SIZE} color={ICON_COLOR} onClick={() => go('https://www.facebook.com/Live-Rewards-107599831487842')} />
				</Icons>
			</Cont>
		</HeaderB>
	);
};

const HeaderB = styled.div`
	position: relative;
	z-index: 10;
	//margin-top: -105px;
`;

const Cont = styled(BoxWrapper)`
	max-width: 1200px;
	@media only screen and (max-width: 1200px) {
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 600px) {
		//padding-bottom: 30px;
	}
`;

const Icons = styled(Row)`
	@media only screen and (max-width: 700px) {
		div {
			height: 18px;
			width: 18px;
			margin: 0px 3px;
		}
	}
`;

const Iconbtn = styled(IconButton)`
	margin: 0px 5px;
	border-radius: 50px;
	background-color: #eeeeee;
	padding: 8px;

	transition: opacity 0.1s ease-in-out;

	&:hover {
		opacity: 0.3;
	}
`;

const TextBtn = styled(Text)`
	margin: 0px 20px;
	cursor: pointer;
	font-weight: 600;
	color: white;

	transition: opacity 0.1s ease-in-out;

	&:hover {
		opacity: 0.3;
	}
`;

const Inpt = styled.input`
	outline: none;
	border: none;
	background-color: #f1f1f1;
	padding: 14px 22px 14px 12px;
	border-radius: 10px;

	&::placeholder {
		color: #6e8098;
	}
`;

const GetaccesBtn = styled.button`
	padding: 10px 10px;
	outline: none;
	border-radius: 6px;
	margin: 0px 10px;
	border: none;
	cursor: pointer;
	font-weight: bold;
	color: white;
	background-color: #007bff;

	transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

	&:hover {
		opacity: 0.7;
		transform: translateY(-2px);
	}
`;
