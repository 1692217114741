import * as React from 'react';
import styled from 'styled-components';
import { Row, Spacer, Text } from '../../parts';

interface ContactViewProps {}

export const ContactView: React.FC<ContactViewProps> = (props) => {
	const {} = props;

	return (
		<ContactViewBody>
			<Spacer size={30} />
			<Text size={70} weight="bold" style={{ textAlign: 'center' }}>
				Get in touch
			</Text>
			<Spacer size={8} />
			<Text size={22} style={{ fontWeight: 500, opacity: 0.7 }}>
				Trained professional support staff dedicated to getting the help you need.
			</Text>
			<Spacer size={90} />

			<Boxes>
				<Box>
					<Text>Live chat</Text>
					<Text>
						<strong>Weekdays:</strong> 8 AM — 6 PM PST
					</Text>
					<Text>
						<strong>Weekends:</strong> 9 AM — 5 PM PST
					</Text>
				</Box>
				<Box>
					<Text>Email contact</Text>
					<Text>Can’t chat with us during those hours? We’ll respond to you via email within a day.</Text>
					<LinkText onClick={() => (window.location.href = 'mailto:info@liverewardsgo.com?subject=Subject&body=message%20goes%20here')}>info@liverewardsgo.com</LinkText>
				</Box>
				<Box>
					<Text>SMS Support</Text>
					<Text>Text 'REWARDS' to 33339 to opt-in for text message support </Text>
				</Box>
			</Boxes>

			<Spacer size={55} />

			<Text style={{ fontWeight: 800, color: '#1372ed', letterSpacing: 0, fontSize: 16 }}>OUR SUPPORT TEAM</Text>

			<Spacer size={12} />

			<Text size={40} weight="bold">
				Talk to real people
			</Text>
			<Spacer size={10} />
			<Text size={20} style={{ textAlign: 'center', opacity: 0.6, maxWidth: 710, letterSpacing: 0.5 }}>
				Having issues redeeming an offer? or just have questions about our app? give us a call at <strong>+1 (877) 744 7606</strong> and our amazing customer service team will be more than happy
				to assist you.
			</Text>
			<Spacer size={150} />
			<Spacer size={150} />
		</ContactViewBody>
	);
};

const ContactViewBody = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* text-align: center; */
	@media only screen and (min-width: 900px) {
		padding: unset;
		max-width: 900px;
		margin: 0 auto;
	}
`;

const Boxes = styled(Row)`
	justify-content: space-between;

	@media only screen and (max-width: 780px) {
		flex-wrap: wrap;
	}
`;

const LinkText = styled(Text)`
	font-weight: bold;
`;

const Box = styled.div`
	border-radius: 12px;
	background-color: #eeeeee;
	padding: 25px;
	/* width: 230px; */
	width: 90%;
	margin: 10px;

	@media only screen and (min-width: 780px) {
		&:nth-child(1) {
			margin-left: 0;
		}
		&:nth-child(3) {
			margin-right: 0;
		}
	}

	height: 100px;

	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		text-align: center;
		&:nth-child(1) {
			font-size: 22px;
			font-weight: 600;
			margin-bottom: 10px;
		}
		&:nth-child(2) {
			letter-spacing: 0.3px;
			opacity: 0.7;
			/* font-weight: bold; */
		}
		&:nth-child(3) {
			letter-spacing: 0.3px;
			opacity: 0.7;
			/* font-weight: bold; */
		}
	}
`;
