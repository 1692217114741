import * as React from 'react';
import styled from 'styled-components';
import { Row, Spacer, Text } from '../parts';
import { Icon } from '../parts/icon/Icon';
import ColoredLogo from '../assets/logo_white.svg';

export const Footer: React.FC = () => {
	const iconSize = 25;

	const go = (link: string, self?: boolean) => {
		window.open(link, self ? '_self' : '_blank');
	};

	return (
		<FooterBody>
			<Container>
				<TopPart>
					<Row style={{ alignItems: 'flex-start' }}>
						<img src={ColoredLogo} height="80" alt="footer_logo" style={{ height: 80 }} />
						{/* <Text color="white" style={{ marginLeft: -11, marginTop: 5 }} size={13} >™</Text> */}
						<Spacer size={20} />
						<Boxes>
							<Box>
								<Heading>About</Heading>
								<LinkText onClick={() => go('/about', true)}>About Live Rewards</LinkText>
								<LinkText onClick={() => go('/terms', true)}>Terms and Service</LinkText>
								<LinkText onClick={() => go('/privacy', true)}>Privacy & Policy</LinkText>
								<LinkText onClick={() => go('/press', true)}>Press & Media</LinkText>
							</Box>
							<Spacer size={50} />
							<Box>
								<Heading>Membership</Heading>
								<LinkText onClick={() => go('/', true)}>Get membership</LinkText>
								<LinkText onClick={() => go('/cancel', true)}>Cancel membership</LinkText>
							</Box>
							<Spacer size={50} />
							<Box>
								<Heading>Business</Heading>
								<LinkText onClick={() => go('/business', true)}>Join Network</LinkText>
								<LinkText onClick={() => go('/partnership', true)}>Partnership</LinkText>
							</Box>
							<Spacer size={50} />
							<Box>
								<Heading>Get Support</Heading>
								<LinkText onClick={() => go('/contact', true)}>Contact Us</LinkText>
							</Box>
						</Boxes>
					</Row>
					<Socials>
						<IconBtn name="instagram" size={iconSize} color="white" onClick={() => go('https://www.instagram.com/liverewardstoday/')} />
						<IconBtn name="tiktok" size={iconSize} color="white" onClick={() => go('https://www.tiktok.com/@liverewards?is_copy_url=1&is_from_webapp=v1')} />
						<IconBtn name="twitter" size={iconSize} color="white" onClick={() => go('https://twitter.com/LiveRewardsgo')} />
						<IconBtn name="linkedin" size={iconSize} color="white" onClick={() => go('https://www.linkedin.com/company/live-rewards/')} />
						<IconBtn name="facebook" size={iconSize} color="white" onClick={() => go('https://www.facebook.com/Live-Rewards-107599831487842')} />
					</Socials>
				</TopPart>
				<Spacer size={20} />

				<BottomPart>
					<Text>© {new Date().getFullYear()} Live Rewards All rights reserved</Text>
				</BottomPart>

				<BottomPart>
					<a href="https://www.bbb.org/us/ca/huntingtn-bch/profile/ecommerce/live-rewards-1126-1000089502/#sealclick" target="_blank" rel="nofollow">
						<img
							src="https://seal-orangecounty.bbb.org/seals/black-seal-187-130-whitetxt-bbb-1000089502.png"
							height="50"
							style={{ border: 0 }}
							alt="Live Rewards BBB Business Review"
						/>
					</a>
				</BottomPart>

				<Text style={{ padding: 20, color: 'white' }}>
					<strong>IMPORTANT DISCLAIMER ABOUT SSL:</strong> SSL is not a debt consolidation company, a government agency, an agency or agent of any government agency, a law firm, a
					debt relief services company, or a tax debt relief company. SSL does not participate in, provide, or assist any person or entity in promoting, offering for sale, selling,
					marketing, advertising, and/or providing debt relief services, student loan consolidation services, student loan debt relief services, legal services, or tax advice or
					preparation services. Further, SSL does not request, obtain, or use consumer reports and does not use, obtain, offer, provide, sell, or arrange for others to use or
					obtain prescreened consumer reports.
				</Text>

				<Spacer size={10} />
			</Container>
		</FooterBody>
	);
};

const FooterBody = styled.div`
	background-color: #292f36;
	width: 100%;
`;

const Container = styled.div`
	justify-content: space-between;
	align-items: flex-start;
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	@media only screen and (min-width: 1200px) {
		max-width: 1200px;
		margin: 0 auto;
	}
`;

const Boxes = styled(Row)`
	flex-wrap: wrap;
	align-items: flex-start;
`;

const TopPart = styled(Row)`
	flex-wrap: wrap;
	width: calc(100% - 40px);
	justify-content: space-between;
	padding: 20px;
	@media only screen and (max-width: 500px) {
		padding: 20px;
	}
`;

const Socials = styled(Row)`
	align-items: center;
	justify-content: space-between;
	align-items: baseline;
	> div {
		cursor: pointer;
		transition: opacity 0.2s ease-in-out;
		&:hover {
			opacity: 0.5;
		}
	}
	@media only screen and (max-width: 500px) {
		margin-top: 30px;
	}
`;

const Box = styled.div``;
const Heading = styled(Text).attrs({ size: 22, weight: 'bold', color: '#fff' })``;
const LinkText = styled(Text).attrs({ size: 13, weight: 'medium', color: '#dddddd' })`
	cursor: pointer;
	opacity: 0.7;
	&:hover {
		text-decoration: underline;
		opacity: 1;
	}
`;

const BottomPart = styled(Row)`
	border-top: solid 2px #404850;
	width: calc(100% - 40px);
	padding-top: 10px;
	p {
		color: white;
	}
	margin: 20px;
	@media only screen and (max-width: 500px) {
		margin: 0px 20px;
		width: calc(100% - 40px);
	}
`;

const IconBtn = styled(Icon)`
	margin: 0px 5px;
`;
