import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import styled from 'styled-components';
import { Spacer, Text } from '../../parts';
import { request } from '../../utils';

interface FormModalProps {
	onClose: () => void;
}

export const FormModal: React.FC<FormModalProps> = ({ onClose }) => {
	const alert = useAlert();
	const [loading, SetLoading] = useState(false);

	const [Name, setName] = useState('');
	const [desc, setdesc] = useState('');
	const [FirstName, setFirstName] = useState('');
	const [LastName, setLastName] = useState('');
	const [JobPosition, setJobPosition] = useState('');
	const [Phone, setPhone] = useState('');
	const [Email, setEmail] = useState('');
	const [City, setCity] = useState('');
	const [State, setState] = useState('');
	const [PostalCode, setPostalCode] = useState('');
	const [HowHearAboutUs, setHowHearAboutUs] = useState('');

	const [Offer1, setOffer1] = useState('');
	const [Offer2, setOffer2] = useState('');
	const [Offer3, setOffer3] = useState('');
	const [Offer4, setOffer4] = useState('');
	const [Offer5, setOffer5] = useState('');
	const [Offer6, setOffer6] = useState('');
	const [Offer7, setOffer7] = useState('');
	const [Offer8, setOffer8] = useState('');
	const [Offer9, setOffer9] = useState('');
	const [Offer10, setOffer10] = useState('');

	const [FileOne, setFileOne] = useState<any>({});
	const [FileTwo, setFileTwo] = useState<any>({});
	const [FileThree, setFileThree] = useState<any>({});

	const composeEmail = async () => {
		if (loading) return;

		SetLoading(true);

		if (!Email || !Name || !desc || !FirstName || !LastName || !JobPosition || !Phone || !City || !State || !PostalCode) {
			SetLoading(false);
			alert.error('Missing the required fields');
			return;
		}
		// Build formdata
		const fd = new FormData();
		fd.append('image_1', FileOne);
		fd.append('image_2', FileTwo);
		fd.append('image_3', FileThree);
		fd.append('business_name', Name);
		fd.append('business_desc', desc);
		fd.append('first_name', FirstName);
		fd.append('last_name', LastName);
		fd.append('title', JobPosition);
		fd.append('phone_number', Phone);
		fd.append('email', Email);
		fd.append('city', City);
		fd.append('state', State);
		fd.append('postal_code', PostalCode);
		fd.append('how_heard_aboutus', HowHearAboutUs);
		fd.append('offer_1', Offer1);
		fd.append('offer_2', Offer2);
		fd.append('offer_3', Offer3);
		fd.append('offer_4', Offer4);
		fd.append('offer_5', Offer5);
		fd.append('offer_6', Offer6);
		fd.append('offer_7', Offer7);
		fd.append('offer_8', Offer8);
		fd.append('offer_9', Offer9);
		fd.append('offer_10', Offer10);

		const a = await request('post', 'business/apply', fd, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			path: process.env.NODE_ENV === 'production' ? 'https://portal_api.liverewardsgo.com/' : 'http://localhost:8080/'
		});

		// console.log('message', a);

		//
		alert.success('Form submitted');
		SetLoading(false);
		onClose();
	};

	useEffect(() => {
		document.body.style.overflow = 'hidden';

		return () => {
			document.body.style.overflow = 'scroll';
		};
	}, []);

	return (
		<FormModalBody>
			<Box>
				<Spacer size={50} />
				<FormBox>
					<Text size={20} weight='bold' style={{ marginBottom: 10 }}>
						BUSINESS INFORMATION
					</Text>

					<Spacer size={10} />
					<Text size={14} style={{ fontStyle: 'italic', marginBottom: 10 }} color='#eb1d1d'>* required</Text>

					<Input placeholder='Business Name' onChange={(v) => setName(v.target.value)} />
					<TextAread placeholder='Business Description' onChange={(v) => setdesc(v.target.value)}
										 style={{ height: 100 }} />
					<Input placeholder='First name' onChange={(v) => setFirstName(v.target.value)} />
					<Input placeholder='Last name' onChange={(v) => setLastName(v.target.value)} />
					<Input placeholder='Title' onChange={(v) => setJobPosition(v.target.value)} />
					<Input placeholder='Phone number' onChange={(v) => setPhone(v.target.value)} />
					<Input placeholder='Email' onChange={(v) => setEmail(v.target.value)} />
					<Input placeholder='City' onChange={(v) => setCity(v.target.value)} />
					<Input placeholder='State' onChange={(v) => setState(v.target.value)} />
					<Input placeholder='Postal Code' onChange={(v) => setPostalCode(v.target.value)} />
					<Input placeholder='How did you hear about us?' onChange={(v) => setHowHearAboutUs(v.target.value)} />

					<Text size={20} weight='bold' style={{ marginBottom: 10, marginTop: 50 }}>
						COUPON OFFER
					</Text>
					<Spacer size={10} />

					<InputHeading>Offer(s) (Include any related promo codes). Promotional offers must not be available to the
						general public.</InputHeading>
					<Input placeholder='' onChange={(v) => setOffer1(v.target.value)} />

					<InputHeading>On average how much will a member save with this offer?</InputHeading>
					<Input onChange={(v) => setOffer2(v.target.value)} />

					<InputHeading>Average Transaction:</InputHeading>
					<Input onChange={(v) => setOffer3(v.target.value)} />

					<InputHeading>Price of Items:</InputHeading>
					<Input onChange={(v) => setOffer4(v.target.value)} />

					<InputHeading>How will the customer redeem offer?</InputHeading>
					<TextAread onChange={(v) => setOffer5(v.target.value)} />

					<InputHeading>Website URL</InputHeading>
					<Input onChange={(v) => setOffer6(v.target.value)} />

					<InputHeading>Terms & Conditions</InputHeading>
					<TextAread onChange={(v) => setOffer7(v.target.value)} />

					<InputHeading>Upload Your Locations: (for multiple locations)</InputHeading>

					{/* @ts-ignore*/}
					<FileInput accept='image/png, image/jpeg' onChange={(v) => setFileOne(v?.target?.files[0])} />

					<InputHeading>Facebook Link</InputHeading>
					<Input onChange={(v) => setOffer8(v.target.value)} />

					<InputHeading>Instagram Link</InputHeading>
					<Input onChange={(v) => setOffer9(v.target.value)} />

					<InputHeading>Twitter Link</InputHeading>
					<Input onChange={(v) => setOffer10(v.target.value)} />

					<InputHeading>Upload your Logo</InputHeading>

					{/* @ts-ignore*/}
					<FileInput accept='image/png, image/jpeg' onChange={(v) => setFileTwo(v?.target?.files[0])} />

					<InputHeading>Images</InputHeading>

					{/* @ts-ignore*/}
					<FileInput accept='image/png, image/jpeg' onChange={(v) => setFileThree(v?.target?.files[0])} />

					<Button onClick={() => composeEmail()} d={loading} >
						Submit
					</Button>
					<Button onClick={() => onClose()} style={{ background: 'gray', marginTop: 15 }}>
						Close
					</Button>
				</FormBox>
			</Box>
		</FormModalBody>
	);
};

const FormModalBody = styled.div`
  background-color: #ffffffae;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 20;
  top: 0;
  justify-content: center;
  display: flex;
  backdrop-filter: blur(5px);
`;

const Box = styled.div`
  width: fit-content;
`;

const FormBox = styled.div`
  box-shadow: #0000003b 0 0 50px;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 0 auto;
  background-color: white;
  padding: 35px;
  border-radius: 12px;

  overflow-y: scroll;
  max-height: 80%;

  input {
    margin-bottom: 10px;
  }
`;

const Input = styled.input`
  padding: 12px;
  border-radius: 5px;
  /* width: 250px; */
  width: calc(100% - 25px);
  outline: none;
  /* background-color: #ececec; */
  /* border: solid 1px black; */
  border: solid 1px #e7e7e7;
`;

const TextAread = styled.textarea`
  border: solid 1px #e7e7e7;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 12px;
  min-height: 50px;
`;

const Button = styled.button<{d?: boolean}>`
  padding: 12px;
  border-radius: 8px;
	margin-top: 20px;
	font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  background-color: #1372ed;
  font-weight: bold;

  transition: opacity 0.1s ease;

	${({d}) => d ? 'opacity: 0.5;pointer-events: none;' : ''}
  &:hover {
    opacity: 0.7;
  }
`;

const InputHeading = styled(Text)`
  margin-bottom: 5px;
`;

const FileInput = styled.input.attrs({ type: 'file' })`
  padding: 25px 0;
  padding-top: 0;
  height: 200px;
`;
