import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Icon } from './icon/Icon';

interface RowConfig {
	style?: CSSProperties;
	center?: boolean;
	children?: React.ReactNode;
}

export const Row: React.FC<RowConfig> = (p) => {
	const { children, style } = p;
	return (
		<RowBody {...p} style={style}>
			{children}
		</RowBody>
	);
};

const RowBody = styled.div<RowConfig>`
	display: flex;
	align-items: center;
	${({ center }) => center && 'justify-content: center;'}
`;

export const Spacer: React.FC<{ size: number }> = ({ size }) => <SpaceBody s={size} />;

const SpaceBody = styled.div<{ s: number }>`
	${({ s }) => `
  height: ${s}px;
  width: ${s}px;
  `}
`;

export const Fill = styled.div`
	display: flex;
	flex-grow: 1;
	flex: 1;
`;

interface BoxWrapperConf {
	row?: boolean;
	style?: CSSProperties;
	smaller?: boolean;
	children?: React.ReactNode;
}

export const BoxWrapper: React.FC<BoxWrapperConf> = (p) => {
	return (
		<BoxWrapperB {...p} $row={p.row} $smaller={p.smaller}>
			{p.children}
		</BoxWrapperB>
	);
};

const BoxWrapperB = styled.div<{ $row: boolean; $smaller: boolean }>`
	display: flex;
	flex-direction: ${({ $row }) => ($row ? 'row' : 'column')};
	align-items: center;
	padding: 0px 20px;
	${({ $smaller }) => `
	@media only screen and (min-width: ${$smaller ? 800 : 1100}px) {
		justify-content: center;
		max-width: ${$smaller ? 800 : 1100}px;
		align-items: center;
		margin: 0 auto;
		
	}
	`}
`;

interface IconButtonProps extends CSSProperties {
	name: string;
	color: string;
	size: number;
	onClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = (p) => {
	return <IconButtonB {...p} name={p.name} color={p.color} size={p.size} />;
};

const IconButtonB = styled(Icon)`
	cursor: pointer;

	&:hover {
		fill: red;
	}
`;

interface CenterType {
	style?: CSSProperties;
	children?: React.ReactNode;
}

export const Center: React.FC<CenterType> = (p) => {
	return <CenterB {...p}>{p.children}</CenterB>;
};

const CenterB = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
