import * as React from 'react';
import styled from 'styled-components';
import{Facebook}from'./icons/Facebook';import{Instagram}from'./icons/Instagram';import{IosstoreBadge}from'./icons/IosstoreBadge';import{Linkedin}from'./icons/Linkedin';import{PlaystoreBadge}from'./icons/PlaystoreBadge';import{Search}from'./icons/Search';import{Star}from'./icons/Star';import{Tiktok}from'./icons/Tiktok';import{Twitter}from'./icons/Twitter';
interface IconProps {
  name: string;
  color?: any;
  size?: any;
  style?: React.CSSProperties;
  className?: any;
  onClick?: any;
}
export const Icon: React.FC<IconProps> = (props) => {
  const {style, className, onClick} = props;
  return (
    <IconBody className={className} style={{...style}} onClick={onClick}>
      { props.name === 'facebook' && <Facebook color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'instagram' && <Instagram color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'iosstore-badge' && <IosstoreBadge color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'linkedin' && <Linkedin color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'playstore-badge' && <PlaystoreBadge color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'search' && <Search color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'star' && <Star color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'tiktok' && <Tiktok color={props.color} style={{ width: props.size, height: props.size }} /> }{ props.name === 'twitter' && <Twitter color={props.color} style={{ width: props.size, height: props.size }} /> }
    </IconBody>
  );
};
const IconBody = styled.div`display: flex;width: fit-content;`;
